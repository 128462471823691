import { RestClientsContext } from '@sekoia/shared/contexts/RestClients/RestClientsContext';
import { ai } from '@sekoia/shared/utils/telemetryService';
import { useContext } from 'react';

const useCompassClient = () => {
  const { compass } = useContext(RestClientsContext);

  if (!compass) {
    ai.trackException(
      `No Compass client found, did you forget to wrap the application with a <CompassClientProvider />?`,
    );
    throw new Error(
      `No Compass client found, did you forget to wrap the application with a <CompassClientProvider />?`,
    );
  }

  return compass;
};

export default useCompassClient;
