/* eslint-disable no-console */
import { Authentication } from 'src/utils/authentication/Authentication';
import currentProfileProvider from 'src/utils/CurrentProfileProvider/CurrentProfileProvider';
import { ai } from '@sekoia/shared/utils/telemetryService';
import { v4 as uuidv4 } from 'uuid';
import * as errorActions from 'src/redux/error/errorActions';
import * as cacheActions from 'src/redux/cache/cacheActions';
import * as connectionActions from 'src/redux/connection/connectionActions';
import * as appSettingsActions from 'src/redux/appSettings/appSettingsActions';
import { EXCEPTION_THROWN_STATUS_CODE } from 'src/utils/constants';
import { fetch } from 'whatwg-fetch';
import { canFetchDns } from 'src/utils/connection/testConnection';
import { getSessionId } from 'src/utils/sessionId';
import store from 'src/redux/store';

class Rest {
  constructor() {
    this.currentRunningPaths = {};
  }

  showErrorUI = (message, retryHandler, forceRetry) => {
    store.dispatch(errorActions.add(message, retryHandler, forceRetry));
    return true;
  };

  // Check if some of the same requests were added and resolve their promise
  clearRunningQueue(pathAsString, error, response) {
    if (pathAsString && this.currentRunningPaths[pathAsString]) {
      this.currentRunningPaths[pathAsString].forEach(([resolve, reject]) => {
        if (error) {
          reject(error);
        } else {
          resolve(response);
        }
      });
      delete this.currentRunningPaths[pathAsString];
    }
  }

  async executeSelector(request, requestUrl = undefined) {
    const result = await this.execute(request, currentProfileProvider.Instance.getCurrentProfile(), requestUrl);
    const selector = cacheActions.generateSelector(request.cachePath);
    return {
      json: result,
      selector: selector,
    };
  }

  getSelector = (request) => cacheActions.generateSelector(request.cachePath);

  async execute(request, profile, requestUrl = undefined) {
    let customerUrl = requestUrl;
    if (!customerUrl) {
      customerUrl = profile.customerUrl;
    }

    // Check cacheValidate, if accepted return cached value without executing request.
    if (typeof request.cacheValidate === 'function' && Array.isArray(request.cachePath)) {
      const cachedValue = store.dispatch(cacheActions.get(request.cachePath));
      if (request.cacheValidate(cachedValue)) {
        return cachedValue;
      }
    }

    const pathAsString = request.url + (request.cachePath && request.cachePath.join('-'));
    if (pathAsString && !request.allowParallel) {
      // If you are the first to call the request, make an array for subsequent to add their handlers
      if (!this.currentRunningPaths[pathAsString]) {
        this.currentRunningPaths[pathAsString] = [];
      } else {
        // Someone else is waiting for a duplicate request right now
        // Await for it to return and hook into it's return values.
        const json = await new Promise((resolve, reject) => {
          this.currentRunningPaths[pathAsString].push([resolve, reject]);
        }).catch((e) => {
          this.executeErrorHandler(e, request.onError, pathAsString);
        });
        return json;
      }
    }

    const options = {
      method: request.method,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${await Authentication.Instance.getToken()}`,
        'sekoia.session_id': getSessionId(),
        'sekoia.correlation_id': uuidv4(),
        'sekoia.customer_id': profile.customerId,
      },
    };

    if (request.body) {
      const parsedBody = this.parseBody(request.body, profile);
      options.body = JSON.stringify(parsedBody);
    }
    let response;
    let parsedUrl = this.parseStringVariables(request.url, profile);
    if (parsedUrl.startsWith('/')) parsedUrl = parsedUrl.slice(1);
    try {
      response = await fetch(`${customerUrl}/${parsedUrl}`, options);
    } catch (e) {
      ai.trackException(e, `Request to ${customerUrl}/${parsedUrl} failed`);
      response = {
        status: EXCEPTION_THROWN_STATUS_CODE,
        exceptionWasThrown: true,
      };
    }

    // 1. Check if request was ok and move on.
    if (response.status && response.status >= 200 && response.status <= 299) {
      const json = response.status !== 204 ? await response.json() : {};

      // If we have a cachePath, save it to the cache.
      if (Array.isArray(request.cachePath)) {
        store.dispatch(cacheActions.save(request.cachePath, json, request.groupBy));
      }
      this.clearRunningQueue(pathAsString, null, json);
      return json;
    }

    this.clearRunningQueue(pathAsString, response);
    await this.executeErrorHandler(response, request.onError, pathAsString);
  }

  parseStringVariables(url, profile) {
    if (typeof url !== 'string') return url;

    if (profile && profile.customerId) {
      return url.replace('[[customerId]]', profile.customerId);
    } else {
      return url;
    }
  }

  parseBody(body, profile) {
    Object.keys(body).forEach((key) => {
      body[key] = this.parseStringVariables(body[key], profile);
    });
    return body;
  }

  async executeErrorHandler(response, errorHandler, requestedUrl) {
    let responseBody;
    if (response.status === 401) {
      const loginFromKaruna = store.getState().karunaSettings.get('loginFromKaruna');
      if (loginFromKaruna === false) {
        const isSessionActive = Authentication.Instance.isSessionActive();
        if (!isSessionActive) {
          ai.trackTrace('executeErrorHandler: calling login as session is invalid and status === 401');
          await Authentication.Instance.login();
          return;
        }

        let isNexusUnauthorized;
        try {
          responseBody = await response.clone().json();
          isNexusUnauthorized = this.isNexusUnauthorized(responseBody);
        } catch (e) {
          // The reason we ignore this exeption is that we always want to call our ErrorHandler to display a message to the user.
          // Even though the response is not in a valid format
        }
        if (!isNexusUnauthorized) {
          ai.trackTrace(
            `rest.js, executeErrorHandler: Showing PIN view, because nexus isn't authorized, when calling '${requestedUrl}'`,
          );
          store.dispatch(appSettingsActions.hidePin(false));
        }
      }
    }

    if (response.exceptionWasThrown) {
      const canFetch = await canFetchDns();
      if (!canFetch) {
        store.dispatch(connectionActions.changed('no_connection'));
      }
    }

    const errorResponse = {
      status: response.status,
    };

    if (errorHandler) {
      errorResponse.errorBody = responseBody;
      const result = errorHandler(errorResponse, this.showErrorUI);

      if (result) {
        return;
      }
    }

    this.globalErrorHandler(response);
  }

  async globalErrorHandler(response) {
    ai.trackException(
      new Error(`Uncaught ${response.status} occurred while sending request, response is ${await response.json}`),
    );
    this.showErrorUI('global:errorMessages.generic');
  }

  isNexusUnauthorized(errorResponse) {
    if (!errorResponse) {
      return false;
    }
    if (errorResponse.ErrorMessage && errorResponse.ErrorMessage === 'Unauthorized by Nexus') {
      return true;
    }
    return false;
  }
}

const Instance = new Rest();
export default Instance;
