import { Skeleton } from '@mui/material';
import React, { useMemo } from 'react';
import { useFetchUser } from '@sekoia/shared/requests/atlas/useFetchUser';
import { useFetchEmployeeById } from '@sekoia/shared/requests/atlas/useFetchEmployeeById';

type AtlasUserDisplaynameProps = {
  userId: string;
  includeDeleted?: boolean;
  style?: React.CSSProperties;
  includeTitle?: boolean;
};

export const AtlasUserFirstAndLastname: React.FC<React.PropsWithChildren<AtlasUserDisplaynameProps>> = ({
  userId,
  style,
  includeDeleted = true,
  includeTitle = false,
}) => {
  const { data: employeeData, fetchStatus: employeeFetchStatus } = useFetchEmployeeById(userId, true);
  const { data: userData, fetchStatus: userDataFetchStatus } = useFetchUser(userId, employeeData === undefined);

  const name = useMemo(() => {
    if (employeeData)
      return `${employeeData.firstname} ${employeeData.lastname} ${
        includeTitle && employeeData.title ? ` (${employeeData.title})` : ''
      }`;
    if (userData) return userData.fullName;

    return '-';
  }, [employeeData, includeTitle, userData]);

  if (userDataFetchStatus === 'fetching' || employeeFetchStatus === 'fetching') {
    return (
      <span style={{ ...style, display: 'inline-block', width: 50 }}>
        <Skeleton />
      </span>
    );
  }

  if (!includeDeleted && (userData?.deleted || employeeData?.deleted)) {
    return <></>;
  }

  if (style) {
    return <span style={style}>{name}</span>;
  }

  return <>{name}</>;
};
