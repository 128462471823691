export default class ProfileStorageManager {
  private currentProfileKey = 'current_profile';

  public getCurrentProfile() {
    const profile = localStorage.getItem(this.currentProfileKey);
    return profile;
  }

  public setCurrentProfile(value: string): void {
    localStorage.setItem(this.currentProfileKey, value);
  }

  public removeCurrentProfile(): void {
    localStorage.removeItem(this.currentProfileKey);
  }
}
