import React, { memo, useEffect, useState, Suspense } from 'react';
import { useMountUnmountLogger } from 'src/hooks/useMountUnmountLogger';
import { Authentication } from 'src/utils/authentication/Authentication';
import UserManagerSettingsWeb from 'src/utils/authentication/userManagerSettings/UserManagerSettingsWeb';
import LoadingSpinner from './_Layout/LoadingSpinner';
import AuthProvider from './AuthProvider';
import { ApplicationInsightTrackKeys } from 'src/constants/ApplicationInsightTrackKeys';

const AppEmployee = React.lazy(() => import(/* webpackChunkName: "WebEntranceEmployee" */ './AppEmployee'));
const Bootstrap = React.lazy(() => import(/* webpackChunkName: "WebEntranceBootstrap" */ './Bootstrap'));

const WebEntrance = () => {
  useMountUnmountLogger('WebEntrance');

  localStorage.setItem(ApplicationInsightTrackKeys.ENTRANCE, 'Web');

  useEffect(() => {
    Authentication.createInstance(UserManagerSettingsWeb());
    setIsInitialized(true);
  }, []);

  const [isInitialized, setIsInitialized] = useState(false);

  if (!isInitialized) return <LoadingSpinner />;
  return (
    <AuthProvider>
      <Suspense fallback={<LoadingSpinner />}>
        <Bootstrap>
          <AppEmployee />
        </Bootstrap>
      </Suspense>
    </AuthProvider>
  );
};

export default memo(WebEntrance);
