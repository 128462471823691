import i18n from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import * as resources from '@alienfast/i18next-loader!src/locales';
import { Environment } from 'src/utils/environment';

if (Environment.isLocal) {
  i18n.use(initReactI18next).init({
    lng: 'ss',
    debug: true,
    resources,
  });
} else {
  i18n
    .use(I18nextBrowserLanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: {
        'da-DK': ['da'],
        'sv-SE': ['sv'],
        'nl-NL': ['nl'],
        no: ['no-NO'],
        'no-NO': ['no-NO'],
        'no-bok': ['no-NO'],
        'no-nyn': ['no-NO'],
        en: ['en-GB'],
        default: ['en-GB'],
      },
      detection: {
        order: ['navigator'],
      },
      debug: false,
      resources,
    });
}
