import { DeviceInfoManager } from '@sekoia/shared/utils/DeviceInfoManager';
import base64url from 'base64url';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIsNativeFromPath, useIsResidentFromPath } from 'src/hooks/useIsNative';
import { useMountUnmountLogger } from 'src/hooks/useMountUnmountLogger';
import { hidePin as setHidePin } from 'src/redux/appSettings/appSettingsActions';
import { ReduxState } from 'src/redux/storeTypings';
import { Authentication } from 'src/utils/authentication/Authentication';
import { SilentRefresh } from 'src/utils/authentication/SilentRefresh';
import { ai } from '@sekoia/shared/utils/telemetryService';
import { getPublicKey } from 'src/utils/nativeCommunication';
import { PinWrapper } from './Pin/Wrapper/PinWrapper';
import LoadingSpinner from './_Layout/LoadingSpinner';
import { User } from 'oidc-client';
import { ResidentAuthentication } from 'src/utils/authentication/ResidentAuthentication';
import { ApplicationInsightTrackKeys } from 'src/constants/ApplicationInsightTrackKeys';

type Props = {
  children: ReactNode;
  isResidentEntrance?: boolean;
};

const AuthProvider = ({ children, isResidentEntrance = false }: Props) => {
  useMountUnmountLogger('AuthProvider');

  const isNative = useIsNativeFromPath();
  const isResident = useIsResidentFromPath();
  const dispatch = useDispatch();
  const [isInitiallyAuthenticated, setIsInitiallyAuthenticated] = useState(false);
  const hidePin = useSelector((state: ReduxState) => state.appSettings.get('HIDE_PIN') as boolean);

  const userLoadedEvent = useCallback(
    (user?: User, isInitial = false) => {
      Authentication.Instance.isAuthenticated()
        .then(async (isAuth) => {
          const loggedIn = !(!user || !user.access_token) && !user.expired;
          if (loggedIn !== isAuth && !isInitial) {
            ai.trackTrace(
              `AuthProvider, User from userloadedevent has a different 'isAuthenticated' value ${loggedIn} - ${isAuth}`,
            );
          }

          if (!isAuth) {
            const isSessionActive = Authentication.Instance.isSessionActive();

            if (isSessionActive) {
              ai.trackTrace('AuthProvider, userLoadedEvent: showing PIN');
              dispatch(setHidePin(false));
            } else {
              ai.trackTrace('AuthProvider: calling login as session is invalid and status not authenticated');
              await Authentication.Instance.logoutKeepingStorageSettings();
              dispatch(setHidePin(true));

              if (isResident) {
                ai.trackTrace('AuthProvider: calling initiateResidentLogin');
                await ResidentAuthentication.Instance.initiateResidentLogin();
              } else if (isNative) {
                const publicKey = await getPublicKey();
                const deviceId = DeviceInfoManager.Instance.getDeviceId();
                const arcValues = [`deviceId:${deviceId}`, `publicKey:${base64url(publicKey)}`].join(' ');
                ai.trackTrace(`AuthProvider mount: calling login(arcValues), deviceId ${deviceId}`);
                await Authentication.Instance.login(arcValues);
              } else {
                ai.trackTrace('AuthProvider mount: calling login()');
                await Authentication.Instance.login();
              }
            }
          } else {
            setIsInitiallyAuthenticated(true);
          }
        })
        .catch((e) => {
          ai.trackException(e, `Unable to succeed login, refreshing page`);
        });
    },
    [dispatch, isNative, isResident],
  );

  useEffect(() => {
    Authentication.Instance.addUserLoaded(userLoadedEvent);

    return () => {
      Authentication.Instance.removeUserLoaded(userLoadedEvent);
    };
  }, [userLoadedEvent]);

  useEffect(() => {
    if (window.location.hash.indexOf('code') !== -1 && window.location.hash.indexOf('/nexuslogin.html') === -1) {
      Authentication.Instance.authenticate().catch((e) => {
        ai.trackException(e, `Authentication failed, forcing logout`);
        Authentication.Instance.logout();
      });
      return;
    }

    userLoadedEvent(undefined, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPinAccepted = async (): Promise<void> => {
    ai.startTrackPage(ApplicationInsightTrackKeys.PERFORMANCE_PIN_TO_BOTTOMBAR);
    ai.startTrackPage(ApplicationInsightTrackKeys.PERFORMANCE_PIN_TO_TASKLIST);
    await Authentication.Instance.signinSilent();

    dispatch(setHidePin(true));
    setIsInitiallyAuthenticated(true);
  };

  if (!isInitiallyAuthenticated && hidePin) return <LoadingSpinner />;

  return (
    <>
      {!hidePin && !isResidentEntrance ? (
        <PinWrapper onPinAccepted={onPinAccepted} />
      ) : (
        <SilentRefresh>{children}</SilentRefresh>
      )}
    </>
  );
};

export default AuthProvider;
