import 'core-js/stable';
import 'whatwg-fetch';

global.Buffer = global.Buffer || require('buffer').Buffer;

if (!window.location.origin) {
  window.location.origin =
    window.location.protocol +
    '//' +
    window.location.hostname +
    (window.location.port ? ':' + window.location.port : '');
}
