import { UserManagerSettings } from 'oidc-client';
import loginServiceUrl from 'src/utils/authentication/loginServiceUrl';

const UserManagerSettingsWeb = (): UserManagerSettings => {
  const baseAuthUrl = loginServiceUrl();

  const settings = {
    automaticSilentRenew: false,
    authority: `${baseAuthUrl}/.well-known/openid-configuration`,
    post_logout_redirect_uri: `${window.location.protocol}//${window.location.host}/logout.html`,
    redirect_uri: `${window.location.protocol}//${window.location.host}/login.html`,
    response_type: 'code',
    scope: 'api offline_access IdentityServerApi openid',
    silent_redirect_uri: `${window.location.protocol}//${window.location.host}/login.html`,
    client_id: 'onecare',
  };

  return settings;
};

export default UserManagerSettingsWeb;
