import React from 'react';
import { CircularProgress } from '@mui/material';

export const LoadingSpinner = () => {
  return (
    <div
      style={{
        top: '25vh',
        left: '50vw',
        marginLeft: '-25px',
        position: 'fixed',
      }}
    >
      <CircularProgress size={50} />
    </div>
  );
};

export default LoadingSpinner;
