import React, { memo } from 'react';
import Typography from 'src/react/_Shared/Typography/Typography';
import ProfilePictureAvatarRender from '@sekoia/shared/components/Avatar/ProfilePictureAvatarRender';

const PinAvatar = (props) => {
  const { avatarPicUrl, name } = props;

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <ProfilePictureAvatarRender size={64} pictureUrl={avatarPicUrl} name={name} />
      </div>
      <div style={{ textAlign: 'center', paddingTop: 16, fontSize: 24 }}>
        <Typography variant="body1">{name}</Typography>
      </div>
    </>
  );
};

export default memo(PinAvatar);
