import * as types from 'src/redux/constants';

export const hidePin = (value) => ({
  type: types.HIDE_PIN,
  payload: {
    value,
  },
});

export const loginFromLocationBasedDevice = (value) => ({
  type: types.LOGIN_FROM_LOCATION_BASED_DEVICE,
  payload: {
    value,
  },
});

export const isDisabledBottomNavigation = (value) => ({
  type: types.IS_DISABLED_BOTTOM_NAVIGATION,
  payload: {
    value,
  },
});
