/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import { createTheme as createMuiTheme, ThemeOptions } from '@mui/material';
import { COLORS } from './react/_Layout/StyleConstants';

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    dark?: string;
  }
}

const baseTheme = createMuiTheme({
  palette: {
    background: {
      default: '#FFFFFF',
    },
    primary: {
      contrastText: COLORS.primary.contrastText,
      dark: COLORS.primary.dark,
      light: COLORS.primary.light,
      main: COLORS.primary.main,
    },
    secondary: {
      contrastText: COLORS.secondary.contrastText,
      dark: COLORS.secondary.dark,
      light: COLORS.secondary.light,
      main: COLORS.secondary.main,
    },
  },
});

const sekoiaOverrides: ThemeOptions = {
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: COLORS.primary.dark,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: `8px 16px`,
          '&> .MuiList-root': {
            margin: `-8px -24px`,
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: `8px 16px 16px`,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          whiteSpace: 'pre-line',
        },
        body2: {
          color: COLORS.greyText,
          whiteSpace: 'pre-line',
        },
        subtitle1: {
          '& + .MuiList-root': {
            paddingTop: 0,
          },
        },
        h3: {
          fontSize: '0.8rem',
        },
        h4: {
          fontSize: '1rem',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          '&.Mui-disabled': {
            color: COLORS.greyText,
          },
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          '& + .MuiCardActions-root': {
            marginTop: -16,
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          '& + .MuiCardContent-root': {
            paddingTop: 0,
          },
        },
      },
    },
  },
};

export function createTheme() {
  let theme = baseTheme;
  theme = createMuiTheme(theme, sekoiaOverrides);
  return theme;
}
