import React, { memo, Suspense, useEffect, useState } from 'react';
import { useMountUnmountLogger } from 'src/hooks/useMountUnmountLogger';
import { DeviceInfoManager } from '@sekoia/shared/utils/DeviceInfoManager';
import { getLoginType } from 'src/utils/authentication/getLoginType';
import { ResidentAuthentication } from 'src/utils/authentication/ResidentAuthentication';
import UserManagerSettingsResident from 'src/utils/authentication/userManagerSettings/UserManagerSettingsResident';
import useQueryParameter from 'src/utils/customHooks/useQueryParameter';
import { Authentication } from 'src/utils/authentication/Authentication';
import UserManagerSettingsNative from 'src/utils/authentication/userManagerSettings/UserManagerSettingsNative';
import { LocationPinAuthentication } from 'src/utils/authentication/locationPinLogin/LocationPinAuthentication';
import LoadingSpinner from './_Layout/LoadingSpinner';
import UserManagerSettingsLocationBased from 'src/utils/authentication/userManagerSettings/UserManagerSettingsLocationBased';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@mui/material';
import AuthProvider from './AuthProvider';
import { ai } from '@sekoia/shared/utils/telemetryService';
import { ApplicationInsightTrackKeys } from 'src/constants/ApplicationInsightTrackKeys';

const MINIMUM_APP_VERSION = {
  major: 2,
  minor: 12,
  patch: 1,
};

const AppEmployee = React.lazy(() => import(/* webpackChunkName: "NativeEntranceApp" */ './AppEmployee'));
const Bootstrap = React.lazy(() => import(/* webpackChunkName: "NativeEntranceBootstrap" */ './Bootstrap'));

const NativeEntrance = () => {
  useMountUnmountLogger('NativeEntrance');

  localStorage.setItem(ApplicationInsightTrackKeys.ENTRANCE, 'Native');

  const deviceId = useQueryParameter('device_id');
  if (deviceId) {
    DeviceInfoManager.Instance.setDeviceId(deviceId);
  }
  const customerId = useQueryParameter('customer_id');
  if (customerId) {
    DeviceInfoManager.Instance.setCustomerId(customerId);
  }
  const residentId = useQueryParameter('resident_id');
  if (residentId) {
    DeviceInfoManager.Instance.setResidentId(residentId);
  }
  const appVersion = useQueryParameter('app_version');
  const { t } = useTranslation('global');

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const settings = UserManagerSettingsNative();
    Authentication.createInstance(settings);

    const checkLoginType = async () => {
      try {
        const loginType = await getLoginType();

        switch (loginType.type) {
          case 'LocationLogin':
            Authentication.createInstance(UserManagerSettingsLocationBased());
            await Authentication.Instance.logoutKeepingStorageSettings();
            await LocationPinAuthentication.Instance.initiateLocationPinLogin();
            break;
          case 'ResidentLogin':
            Authentication.createInstance(UserManagerSettingsResident());
            await Authentication.Instance.logoutKeepingStorageSettings();
            await ResidentAuthentication.Instance.initiateResidentLogin();
            break;
          default:
            setIsInitialized(true);
            break;
        }
      } catch (e) {
        setIsInitialized(true);
        ai.trackException(e, 'failed to determine loginType');
      }
    };

    checkLoginType();
  }, []);

  const validateAppVersion = (appVersion: string | null) => {
    if (!appVersion || appVersion === '') return true;
    const appVersionSplit = appVersion.split('.');
    const major = parseInt(appVersionSplit[0]);
    const minor = parseInt(appVersionSplit[1]);
    const patch = parseInt(appVersionSplit[2]);
    if (major < MINIMUM_APP_VERSION.major) return false;
    if (major > MINIMUM_APP_VERSION.major) return true;
    if (minor < MINIMUM_APP_VERSION.minor) return false;
    if (minor > MINIMUM_APP_VERSION.minor) return true;
    if (patch < MINIMUM_APP_VERSION.patch) return false;
    return true;
  };

  if (!isInitialized) return <LoadingSpinner />;

  if (!validateAppVersion(appVersion))
    return (
      <Box height={'100%'}>
        <Grid container direction="row" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
          <Grid item>
            <Grid container spacing={3}>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Typography variant={'h6'}>{t('global:appVersionToLow.title')}</Typography>
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Typography variant={'body1'} style={{ marginBottom: 16 }}>
                  {t('global:appVersionToLow.description')}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <a href="https://play.google.com/store/apps/details?id=com.sekoia&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                  <img
                    alt="Get it on Google Play"
                    style={{ width: 250, height: 'auto' }}
                    src="images/google-play-badge.png"
                  />
                </a>
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <a href="https://apps.apple.com/us/app/sekoia/id1442222310?itsct=apps_box&amp;itscg=30200">
                  <img
                    alt="Download on the App Store"
                    style={{ width: 250, height: 'auto' }}
                    src="images/Download_on_the_App_Store_Badge.png"
                  />
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );

  return (
    <AuthProvider>
      <Suspense fallback={<LoadingSpinner />}>
        <Bootstrap isNativeBasesDevice={true}>
          <AppEmployee />
        </Bootstrap>
      </Suspense>
    </AuthProvider>
  );
};

export default memo(NativeEntrance);
