import { DeviceInfoManager } from '@sekoia/shared/utils/DeviceInfoManager';
import { getSignedMessage } from 'src/utils/nativeCommunication';
import { ai } from '@sekoia/shared/utils/telemetryService';
import { v4 as uuidv4 } from 'uuid';
import { Authentication } from 'src/utils/authentication/Authentication';
import loginServiceUrl from 'src/utils/authentication/loginServiceUrl';

export class LocationPinAuthentication {
  public static Instance = new LocationPinAuthentication();

  private _authBaseUrl = loginServiceUrl();

  public async initiateLocationPinLogin() {
    const salt = uuidv4();
    const deviceId = DeviceInfoManager.Instance.getDeviceId();

    let signature;
    try {
      signature = await getSignedMessage(deviceId + salt);
    } catch (error) {
      ai.trackException(error);
    }

    this.navigateToLocationBasedPinLogin(signature, salt);
  }

  private navigateToLocationBasedPinLogin(signature: string, salt: string) {
    const deviceId = DeviceInfoManager.Instance.getDeviceId();
    const customerId = DeviceInfoManager.Instance.getCustomerId();

    if (!deviceId || !customerId) {
      throw Error(`'deviceId' or 'customerId' not present on device!`);
    }

    Authentication.Instance.login(new LocationBasedArguments(salt, signature, deviceId, customerId).acrValues());
  }
}

class LocationBasedArguments {
  salt: string;
  signature: string;
  deviceId: string;
  customerId: string;

  constructor(salt: string, signature: string, deviceId: string, customerId: string) {
    this.salt = salt;
    this.signature = signature;
    this.deviceId = deviceId;
    this.customerId = customerId;
  }

  acrValues(): string {
    return [
      `customerId:${this.customerId}`,
      `deviceId:${this.deviceId}`,
      `salt:${this.salt}`,
      `signature:${this.signature}`,
    ].join(' ');
  }
}
