import useCompassClient from '@sekoia/shared/hooks/common/useCompassClient';
import { useQuery } from '@tanstack/react-query';
import { ai } from '@sekoia/shared/utils/telemetryService';
import { useCallback } from 'react';
import CacheKeys from '@sekoia/shared/constants/CacheKeys';

type response = {
  pictureUrl: string | undefined;
};

export interface ErrorType {
  response: {
    status: number;
  };
}

export const useFetchProfilePicture = (customerId: string, globalId: string | undefined, pictureDimension = 44) => {
  const compassClient = useCompassClient();

  const fetchpictureUrl = useCallback(async (): Promise<string> => {
    if (!globalId) return '';
    try {
      const response = await compassClient.get<string>(
        `/api/profiles/customers/${customerId}/profiles/${globalId}/customsizepictureByGlobalId?width=${pictureDimension}&height=${pictureDimension}`,
      );
      const url = response.data as unknown as response;
      return url.pictureUrl ?? '';
    } catch (error) {
      if ((error as ErrorType).response.status === 404) {
        //store an empty object if the server returned 404, no image found, and dont throw an error.
        return '';
      } else throw Error;
    }
  }, [globalId, compassClient, customerId, pictureDimension]);

  return useQuery<string>({
    queryKey: [CacheKeys.PROFILE_PICTURE_URL, globalId, pictureDimension],
    queryFn: () => fetchpictureUrl(),
    gcTime: 60 * 60 * 1000, // 60 minutes,
    throwOnError: (error) => {
      ai.trackException({
        exception: error,
      });
      return false;
    },
  });
};
