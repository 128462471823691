import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { createContext } from 'react';

export const HistoryContext = createContext({});

@withRouter
export default class HistoryProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: props.history.location,
      history: props.history,
    };
  }
  componentDidMount() {
    this.unlisten = this.props.history.listen((location) => {
      if (location !== this.props.location) {
        this.setState({
          location,
        });
      }
    });
  }
  render() {
    const { children } = this.props;
    return <HistoryContext.Provider value={this.state}>{children}</HistoryContext.Provider>;
  }
}
