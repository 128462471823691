/* eslint-disable @typescript-eslint/no-explicit-any */
import { StatusCodeRethrowFilter } from 'src/requests/fetchWrapper';
import store from 'src/redux/store';
import retry, { Options } from 'async-retry';
import { ai } from '@sekoia/shared/utils/telemetryService';
import handleRestError from 'src/redux/requests/nexus/handleRestError';

export async function requestWithRetry<T>(
  func: () => T,
  httpStatusCodeFilter: StatusCodeRethrowFilter | null = null,
  maxAttempts = 3,
): Promise<T | undefined> {
  let response: ReturnType<typeof func> | undefined;

  return await retry(
    async (abortRetry, attempt) => {
      try {
        response = await func();
        return response;
      } catch (err: any) {
        if (
          httpStatusCodeFilter &&
          httpStatusCodeFilter instanceof StatusCodeRethrowFilter &&
          httpStatusCodeFilter.shouldThrow(err)
        ) {
          //Caller has chosen to handle this status code in the calling scope, so we abort the retrying and rethrow the error for the caller to handle. Same applies for when we have
          //reached max amount of retry attempts.
          abortRetry(err);
        } else if (isNonTransientErrorCode(err) || attempt >= maxAttempts) {
          //Error code indicates non-transient error, so we abort the retrying and show generic rest error.
          store.dispatch(handleRestError(err));
          return response;
        }
        //Rethrow the error to trigger retry.
        throw err;
      }
    },
    {
      retries: maxAttempts,
      randomize: true,
      onRetry: (err) => {
        ai.trackException(err, `requestWithRetry: error during request`);
      },
    } as Options,
  );
}

function isNonTransientErrorCode(error: { status: number }) {
  return [400, 401, 403, 404, 405].includes(error.status);
}
