import React from 'react';

export interface CustomerInfoContext {
  customerId?: string;
  customerUrl?: string;
}

export const CustomerInfoContext = React.createContext<CustomerInfoContext>({
  customerId: undefined,
  customerUrl: undefined,
});

export default CustomerInfoContext;
