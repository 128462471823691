import { CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { hidePin } from 'src/redux/appSettings/appSettingsActions';
import { Authentication } from 'src/utils/authentication/Authentication';
import { ai } from '@sekoia/shared/utils/telemetryService';
import PinView from 'src/react/Pin/View/PinView';
import { MessageDialog } from 'src/react/_Layout/MessageDialog';
import currentProfileProvider from 'src/utils/CurrentProfileProvider/CurrentProfileProvider';
import { pinZIndex } from 'src/utils/zIndexConstants';
import { roles } from '@sekoia/shared/domain/User/roles';

type Props = {
  onPinAccepted: () => Promise<void>;
};

export const PinWrapper = ({ onPinAccepted }: Props) => {
  const { t } = useTranslation(['pin', 'global']);
  const [pinEntered, setPinEntered] = useState('');
  const [isLoadingOnPinEntered, setIsLoadingOnPinEntered] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const [wrongPinEnteredCount, setWrongPinEnteredCount] = useState(0);
  const dispatch = useDispatch();

  const deleteEntry = (action: string) => {
    if (action === 'all') {
      setPinEntered('');
    } else {
      let newVal = pinEntered;
      newVal = newVal.slice(0, pinEntered.length - 1);
      setPinEntered(newVal);
    }
  };

  const addToCode = (input: string) => {
    if (pinEntered.length < 4) {
      const newVal = pinEntered + input;
      setPinEntered(newVal);
      if (newVal.length === 4) {
        callPinService(newVal);
      }
    }
  };

  const callPinService = async (enteredPin: string) => {
    setIsLoadingOnPinEntered(true);

    const status = await Authentication.Instance.pinSignin(enteredPin);
    try {
      if (status === 200) {
        await onPinAccepted();
        setPinEntered('');
        setWrongPinEnteredCount(0);
        dispatch(hidePin(true));
      } else if (status === 403) {
        setPinEntered('');
        setWrongPinEnteredCount(wrongPinEnteredCount + 1);
        setShowErrorMessage(true);
      } else if (status === 401) {
        ai.trackTrace('PinWrapper, logout, status == 401');
        setIsSessionExpired(true);
      }
    } finally {
      setIsLoadingOnPinEntered(false);
    }
  };

  const onClose = () => {
    setShowErrorMessage(false);
  };

  const profile = currentProfileProvider.Instance.getCurrentProfile();

  const isResident = profile?.roles.some((x) => roles.citizenUser.name === x);

  if (isResident) {
    Authentication.Instance.logout();
    return null;
  }

  const name = profile ? profile.fullName : '';

  const avatarPicUrl = localStorage.getItem('profilePicUrl');

  return (
    <>
      <PinView
        value={pinEntered}
        onAddToCode={addToCode}
        onDelete={deleteEntry}
        wrongPinEntered={wrongPinEnteredCount}
        avatarPicUrl={avatarPicUrl}
        name={name}
        showChangeUserBtn
        customZindex={pinZIndex}
      />
      <MessageDialog
        open={showErrorMessage}
        title={t('pin:wrongPinTitle')}
        message={t('pin:wrongPinMsg')}
        onClose={onClose}
      />
      {isSessionExpired && (
        <MessageDialog
          open
          title={t('pin:sessionExpiredTitle')}
          message={t('pin:sessionExpiredMsg')}
          onClose={() => {
            Authentication.Instance.logout();
          }}
          buttonText={t('ok')}
        />
      )}
      {isLoadingOnPinEntered && (
        <div
          style={{
            justifyContent: 'center',
            display: 'flex',
            height: '100vh',
            alignItems: 'center',
            backgroundColor: 'rgba(0,0,0,0.14)',
          }}
        >
          <CircularProgress size={50} />
        </div>
      )}
    </>
  );
};
