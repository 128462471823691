import { executeWithRetry, StatusCodeRethrowFilter } from 'src/requests/fetchWrapper';
import loginServiceUrl from 'src/utils/authentication/loginServiceUrl';
import { ai } from '@sekoia/shared/utils/telemetryService';

export async function removeLocationAndPublicKeyForDevice(locationId, deviceId, customerId) {
  await removeDevicePublicKey(deviceId, customerId);

  const request = {
    url: `/api/locations/${locationId}/device`,
    method: 'DELETE',
    body: {
      deviceId,
    },
  };

  await executeWithRetry(request);
}

export async function setLocationForDevice(locationId, deviceId, customerId, publicKey, administratorToken) {
  await setDevicePublicKey(deviceId, customerId, publicKey, administratorToken);

  const request = {
    url: `/api/locations/${locationId}/device`,
    method: 'post',
    body: {
      deviceId,
      AdministratorToken: administratorToken,
    },
  };

  try {
    await executeWithRetry(request, new StatusCodeRethrowFilter(true));
  } catch (error) {
    ai.trackException(error, `Error removing public key`);
    try {
      removeDevicePublicKey(deviceId, customerId);
    } catch (removeError) {
      ai.trackException(removeError, `Error removing public key, after exception from add device`);
    }
    throw error;
  }
}

export async function updateLocationForDevice(locationId, deviceId) {
  const request = {
    url: `/api/locations/${locationId}/device`,
    method: 'put',
    body: {
      deviceId,
    },
  };

  await executeWithRetry(request);
}

export async function getLocationForDevice(deviceId) {
  const request = {
    url: `/api/locations/device/${deviceId}`,
    method: 'get',
  };
  return await executeWithRetry(request, new StatusCodeRethrowFilter(false, [404, 403]));
}

async function removeDevicePublicKey(deviceId, customerId) {
  const request = {
    url: `${loginServiceUrl()}/device/${deviceId}`,
    method: 'DELETE',
    body: {
      customerId,
    },
  };

  await executeWithRetry(request, new StatusCodeRethrowFilter(true));
}

async function setDevicePublicKey(deviceId, customerId, publicKey, administratorToken) {
  const request = {
    url: `${loginServiceUrl()}/device/${deviceId}`,
    method: 'PUT',
    body: {
      publicKey,
      customerId,
      AdministratorToken: administratorToken,
    },
  };

  await executeWithRetry(request, new StatusCodeRethrowFilter(true));
}
