import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ProfilePictureAvatar from '@sekoia/shared/components/Avatar/ProfilePictureAvatar';
import * as connectionActions from 'src/redux/connection/connectionActions';
import { canFetchDns } from 'src/utils/connection/testConnection';
import { ai } from '@sekoia/shared/utils/telemetryService';
import { Authentication } from 'src/utils/authentication/Authentication';
import { useHistory } from 'react-router';
import { messageDialogZIndex } from 'src/utils/zIndexConstants';
import { AtlasUserFirstAndLastname } from '@sekoia/shared/components/Atlas/AtlasUserFirstAndLastname';

type LogoutDialogProps = {
  onClose?: () => void;
  open: boolean;
  customerId?: string;
  currentUserId?: string;
};

const LogoutDialog: React.FC<React.PropsWithChildren<LogoutDialogProps>> = (props) => {
  const { onClose, open, customerId, currentUserId } = props;
  const { t } = useTranslation('global');
  const history = useHistory();

  const onConfirm = async () => {
    ai.trackTrace('LogoutDialog, manual logout');
    await Authentication.Instance.logout().catch(async () => {
      const canFetch = await canFetchDns();
      if (!canFetch) {
        connectionActions.changed('no_connection');
      }
    });
  };
  const onCloseHandler = () => {
    history.goForward();
  };

  return (
    <Dialog open={open} onClose={onCloseHandler} style={{ zIndex: messageDialogZIndex }}>
      <DialogTitle>{t('global:logoutDialogTitle')}</DialogTitle>
      {customerId && currentUserId && (
        <DialogContent>
          <Stack direction={'row'} padding={2} alignItems={'center'}>
            <ProfilePictureAvatar customerId={customerId} globalId={currentUserId} size={32} />
            <Typography pl={1}>
              <AtlasUserFirstAndLastname userId={currentUserId} />
            </Typography>
          </Stack>
        </DialogContent>
      )}
      <DialogActions>
        <Button variant="contained" onClick={onClose ? onClose : onCloseHandler}>
          {t('global:actions.cancel')}
        </Button>
        <Button variant="contained" color="primary" onClick={onConfirm}>
          {t('global:navigation.logout')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogoutDialog;
