import * as errorActions from 'src/redux/error/errorActions';
import * as appSettingsActions from 'src/redux/appSettings/appSettingsActions';
import { canFetchDns } from 'src/utils/connection/testConnection';
import * as connectionActions from 'src/redux/connection/connectionActions';
import { Authentication } from 'src/utils/authentication/Authentication';
import { ApiException as NexusApiException } from '@sekoia/shared/requests/nswagClients/NexusClient';
import { ApiException as HealthMeasurementsApiException } from '@sekoia/shared/requests/nswagClients/HealthMeasurementsClient';
import { ai } from '@sekoia/shared/utils/telemetryService';
import { FetchApiException } from 'src/requests/exceptions/fetchApiException';

export default function HandleRestError(error, supressGlobalErrorDialog = false) {
  return async function (dispatch, getState) {
    if (
      (error instanceof NexusApiException && error.message !== 'Unauthorized by Nexus') ||
      error instanceof FetchApiException ||
      error instanceof HealthMeasurementsApiException
    ) {
      await checkIfUnauthorized(getState(), dispatch, error.status, supressGlobalErrorDialog);
    } else {
      await HandleGenericException(dispatch, error, supressGlobalErrorDialog);
    }
  };
}

async function HandleGenericException(dispatch, error, supressGlobalErrorDialog) {
  const canFetch = await canFetchDns();
  if (!canFetch) {
    dispatch(connectionActions.changed('no_connection'));
  } else {
    ai.trackException(
      new Error(`Uncaught ${error.status} occurred while sending request, response is ${await error.response}`),
    );
    if (supressGlobalErrorDialog) return;
    dispatch(errorActions.add('global:errorMessages.generic'));
  }
}

async function checkIfUnauthorized(store, dispatch, statusCode, supressGlobalErrorDialog) {
  if (statusCode === 401) {
    const loginFromKaruna = store.karunaSettings.get('loginFromKaruna');
    if (loginFromKaruna === false) {
      if (!Authentication.Instance.isSessionActive()) {
        ai.trackTrace('handleRestError, checkIfUnauthorized: calling login as session is invalid and status === 401');
        await Authentication.Instance.login();
      } else {
        ai.trackTrace('handleRestError, checkIfUnauthorized: showing PIN, because of 401.');
        dispatch(appSettingsActions.hidePin(false));
      }
    }
  } else {
    if (supressGlobalErrorDialog) return;
    // eslint-disable-next-line no-console
    console.error('handeRestError, checkIfUnauthorized', statusCode);
    dispatch(errorActions.add('global:errorMessages.generic'));
  }
}
