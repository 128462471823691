import { useTranslation } from 'react-i18next';
import React from 'react';

const GlobalActionsCloseText = () => {
  const { t } = useTranslation('global');

  return <>{t('global:actions.close')} </>;
};

export default GlobalActionsCloseText;
