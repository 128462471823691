import * as moment from 'moment-timezone';

type BasicDecodedJWT = {
  exp: number;
  sub: string;
  iat: number;
  role: string[];
  client_session_expires_at?: string;
  unique_name: string;
  cultureInfoCode: string;
  compassUrl: string;
  'sekoia:CustomerId': string;
  nameid: string;
  'sekoia:AccessControlTags'?: string | string[];
  'sekoia:loginType'?: string;
};

export type IdDecodedJWT = {
  exp: number;
  sub: string;
  iat: number;
  auth_time: number;
};

class JWTTokenHelper {
  public decodeToken<T = BasicDecodedJWT>(token: string) {
    const base64url = token.split('.')[1];
    const base64 = base64url.replace('-', '+').replace('_', '/');

    return JSON.parse(window.atob(base64)) as T;
  }

  public isTokenExpired(token: string, thresholdInSeconds?: number) {
    const tokenExpirationTime = this.decodeToken(token)['exp'];
    const tokenExpirationTimeMoment = moment.unix(tokenExpirationTime);
    const now = moment();
    if (thresholdInSeconds) {
      return now.add(thresholdInSeconds, 'seconds').isAfter(tokenExpirationTimeMoment);
    }

    return now.isAfter(tokenExpirationTimeMoment);
  }
}

export default JWTTokenHelper;
