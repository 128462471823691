class Environment {
  static sekoiaEnvironment = process.env.SEKOIA_ENV;

  static instance: Environment = new Environment();

  get isDev() {
    return Environment.sekoiaEnvironment === 'dev';
  }

  get isLocal() {
    return !Environment.sekoiaEnvironment || Environment.sekoiaEnvironment === 'local';
  }
}

const instance = Environment.instance;
export { instance as Environment };
