import { fromJS } from 'immutable';
import * as types from 'src/redux/constants';

const initialState = fromJS({
  status: 'connected',
});

export default function connectionReducer(state = initialState, action) {
  switch (action.type) {
    case types.CONNECTION_CHANGED: {
      const { status } = action.payload;
      return state.set('status', status);
    }
    default:
      return state;
  }
}
