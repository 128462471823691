import { applyMiddleware, createStore, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import * as reducers from 'src/redux/reducers';
import devConf from 'src/redux/configureStore.dev';
import _ from 'lodash';
import { ai } from '@sekoia/shared/utils/telemetryService';
import { Environment } from '../utils/environment';

let config = {
  middlewares: [thunk],
};

if (process.env.NODE_ENV !== 'production') {
  config = devConf(config);
}

const actionsToIgnoreInLog = ['CACHE_SAVE'];
const loggerMiddleware = () => (next) => (action) => {
  if (actionsToIgnoreInLog.includes(action.type)) {
    next(action);
    return;
  }
  const clonedAction = _.cloneDeep(action);
  if (clonedAction.type === 'DIRECTORY_SEED') {
    clonedAction.payload.dataLength = clonedAction.payload.data.length;
    delete clonedAction.payload.data;
  }
  if (Environment.isDev) {
    ai.trackTrace(clonedAction.type, clonedAction);
  }

  next(action);
};
if (Environment.isDev) {
  config.middlewares.push(loggerMiddleware);
}

const store = createStore(combineReducers(reducers), compose(applyMiddleware(...config.middlewares)));

export default store;
