export class ApplicationInsightTrackKeys {
  static readonly PERFORMANCE_INDEX_TO_BOTTOM_BAR = 'INDEX > BOTTOMBAR';
  static readonly PERFORMANCE_INDEX_TO_TASKLIST = 'INDEX > TASKLIST';
  static readonly PERFORMANCE_PIN_TO_BOTTOMBAR = 'PIN > BOTTOMBAR';
  static readonly PERFORMANCE_PIN_TO_TASKLIST = 'PIN  > TASKLIST';
  static readonly PERFORMANCE_NEXUS_PLANED_GIVEN_LIST = 'NEXUS PLANNED GIVEN ACTION (LIST)';
  static readonly PERFORMANCE_NEXUS_PLANED_CANCEL_LIST = 'NEXUS PLANNED CANCEL ACTION (LIST)';
  static readonly PERFORMANCE_NEXUS_PLANED_GIVEN_DETAIL = 'NEXUS PLANNED GIVEN ACTION (DETAIL)';
  static readonly PERFORMANCE_NEXUS_PLANED_CANCEL_DETAIL = 'NEXUS PLANNED CANCEL ACTION (DETAIL)';
  static readonly PERFORMANCE_NEXUS_NOT_DISPENCED_GIVEN_LIST = 'NEXUS NOT_DISPENCED GIVED ACTION (LIST)';
  static readonly PERFORMANCE_NEXUS_NOT_DISPENCED_CANCEL_LIST = 'NEXUS NOT_DISPENCED CANCEL ACTION (LIST)';
  static readonly PERFORMANCE_NEXUS_NOT_DISPENCED_GIVEN_DETAIL = 'NEXUS NOT_DISPENCED GIVED ACTION (DETAIL)';
  static readonly PERFORMANCE_NEXUS_NOT_DISPENCED_CANCEL_DETAIL = 'NEXUS NOT_DISPENCED CANCEL ACTION (DETAIL)';
  static readonly PERFORMANCE_NEXUS_PN_EFFECT = 'NEXUS PN EFFECT';
  static readonly PERFORMANCE_NEXUS_PN_CANCEL = 'NEXUS PN CANCEL';
  static readonly PERFORMANCE_NEXUS_PLANNED_NOT_GIVEN_DETAIL = 'NEXUS PLANED NOT GIVEN ACTION (DETAIL)';
  static readonly PERFORMANCE_NEXUS_PLANNED_NOT_INGESTED_DETAIL = 'NEXUS PLANED NOT INGESTED ACTION (DETAIL)';
  static readonly PERFORMANCE_NEXUS_NOT_DISPENCED_HANDED_OVER_DETAIL =
    'NEXUS NOT_DISPENCED HANDED OVER ACTION (DETAIL)';
  static readonly PERFORMANCE_NEXUS_NOT_DISPENCED_NOT_GIVEN_DETAIL = 'NEXUS NOT_DISPENCED NOT GIVEN ACTION (DETAIL)';
  static readonly ENTRANCE = 'ENTRANCE';
}
