import { useTranslation } from 'react-i18next';
import React from 'react';
import BaseErrorView from './BaseErrorView';

const TranslatedBaseErrorView: React.FC<React.PropsWithChildren<unknown>> = (props) => {
  const { t } = useTranslation(['global']);

  const { children } = props;

  return (
    <BaseErrorView
      title={t('global:errorMessages.generic')}
      description={t('global:errorMessages.unknown')}
      buttonText={t('global:tryAgain')}
    >
      {children}
    </BaseErrorView>
  );
};

export default TranslatedBaseErrorView;
