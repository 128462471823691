import retry, { Options } from 'async-retry';
import { ai } from '@sekoia/shared/utils/telemetryService';
import timeout from 'src/utils/request/timeout';

export const fetchWithRetry = async (url: string, opts?: RequestInit, timeOutInMs = 3000, maxAttempts = 3) => {
  const options: RequestInit = opts || {};
  const controller = new AbortController();
  options.signal = controller.signal;

  try {
    const response = await retry(
      async () => {
        try {
          return timeout(timeOutInMs, fetch(url, options));
        } catch (innerException: unknown) {
          const timeoutException = innerException as Error;
          if (timeoutException && timeoutException.message === 'timeout') {
            // cancel existing request
            controller.abort();
          }
          throw innerException;
        }
      },
      {
        retries: maxAttempts,
      } as Options,
    );

    if (!response.ok) {
      const error = new Error('fetchWithRetry: Invalid response');
      ai.trackException(error, 'Invalid response', { response: response });
      throw error;
    }

    return response;
  } catch (e) {
    ai.trackException(new Error('fetchWithRetry: Fetch failed'));
    throw e;
  }
};
