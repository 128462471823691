/* eslint-disable @typescript-eslint/no-explicit-any,no-console */
import { ApplicationInsights, IApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { DeviceInfoManager } from '@sekoia/shared/utils/DeviceInfoManager';
import { createBrowserHistory } from 'history';
import { v4 as uuidv4 } from 'uuid';

type SekoiaTelemetryItemData = {
  version?: string;
  customerId?: string;
  deviceId?: string | null;
  lastActivityTimestamp?: string;
  entrance?: string;
  sessionExpiresAt?: string;

  [key: string]: any;
};

type SekoiaTelemetryItem = TelemetryInitializerFnArguments[0] & {
  data?: SekoiaTelemetryItemData;
};

type ExceptionFnArguments = Parameters<IApplicationInsights['trackException']>;
type EventFnArguments = Parameters<IApplicationInsights['trackEvent']>;
type TraceFnArguments = Parameters<IApplicationInsights['trackTrace']>;
type PageViewFnArguments = Parameters<IApplicationInsights['trackPageView']>;
type AddTelemetryInitializerFnArguments = Parameters<IApplicationInsights['addTelemetryInitializer']>;
type TelemetryInitializerFnArguments = Parameters<AddTelemetryInitializerFnArguments[0]>;

export class TelemetryService {
  private _loadedApplicationInsights?: IApplicationInsights;
  private _appInsights: ApplicationInsights;
  private _insightskey: string | undefined = process.env.APPINSIGHTKEY;
  private _sessionId = uuidv4();

  public static Instance: TelemetryService;

  constructor() {
    const INSTRUMENTATION_KEY = this._insightskey;

    const browserHistory = createBrowserHistory({ basename: '' });
    const reactPlugin = new ReactPlugin();
    this._appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: INSTRUMENTATION_KEY,
        disableFetchTracking: false,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory },
        },
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
      },
    });
  }

  private _loadAppInsights() {
    if (!this._loadedApplicationInsights && this._insightskey) {
      this._loadedApplicationInsights = this._appInsights.loadAppInsights();

      this._appInsights.addTelemetryInitializer((envelope) => {
        const typedEnvelop = envelope as SekoiaTelemetryItem;
        if (!typedEnvelop.data) {
          typedEnvelop.data = {};
        }

        typedEnvelop.data.version = process.env.VERSION;
        typedEnvelop.data.customerId = localStorage.getItem('customerId') || 'Unknown';
        if (DeviceInfoManager.Instance.getDeviceId()) {
          typedEnvelop.data.deviceId = DeviceInfoManager.Instance.getDeviceId();
        }

        const lastActivityTimestamp = localStorage.getItem('lastActivityTimestamp');
        typedEnvelop.data.lastActivityTimestamp = lastActivityTimestamp ?? 'Not available';

        const entrance = localStorage.getItem('ENTRANCE');
        typedEnvelop.data.entrance = entrance ?? 'Not available';

        const sessionExpiresAt = localStorage.getItem('sessionExpiresAt');
        typedEnvelop.data.sessionExpiresAt = sessionExpiresAt ?? 'Not available';
      });

      // Redact authorization header
      this._appInsights.addTelemetryInitializer((envelope) => {
        if (envelope.baseData && envelope.baseData.properties && envelope.baseData.properties.requestHeaders) {
          for (const [header] of Object.entries(envelope.baseData.properties.requestHeaders)) {
            if (header.toLowerCase() === 'authorization') {
              envelope.baseData.properties.requestHeaders[header] = 'REDACTED';
            }
          }
        }
      });
    }
  }

  public trackEvent(name: string, customProperties?: EventFnArguments[1]) {
    this._loadAppInsights();

    if (!this._loadedApplicationInsights) {
      console.log('trackEvent:', name, customProperties);
      return;
    }

    const event: EventFnArguments[0] = {
      name,
    };
    this._loadedApplicationInsights.trackEvent(event, customProperties);
  }

  public trackTrace(message: string, customProperties?: TraceFnArguments[1]) {
    this._loadAppInsights();

    if (!this._loadedApplicationInsights) {
      console.info('trackTrace:', message, customProperties);
      return;
    }

    const trace: TraceFnArguments[0] = {
      message,
    };

    this._loadedApplicationInsights.trackTrace(trace, customProperties);
  }

  public trackException(error: unknown, message?: string, customProperties?: ExceptionFnArguments[1]) {
    this._loadAppInsights();

    if (!this._loadedApplicationInsights) {
      if (message) {
        console.error(message);
      }
      console.error(error, customProperties);

      return;
    }

    if (error instanceof Error) {
      this._loadedApplicationInsights.trackException(
        {
          exception: error,
          properties: {
            message,
          },
        },
        customProperties,
      );
    } else if (typeof error === 'string') {
      const e = new Error(error);
      this._loadedApplicationInsights.trackException(
        {
          exception: e,
          properties: {
            message,
          },
        },
        customProperties,
      );
    } else {
      const e = new Error(`Unknown error: ${JSON.stringify(error)}`);
      this._loadedApplicationInsights.trackException(
        {
          exception: e,
          properties: {
            message,
          },
        },
        customProperties,
      );
    }
  }

  public startTrackPage(name: string) {
    this._loadAppInsights();

    if (!this._loadedApplicationInsights) {
      console.info('startTrackPage:', name);
      return;
    }
    this._loadedApplicationInsights.startTrackPage(name);
  }

  public stopTrackPage(name: string, customProperties?: { [key: string]: any }) {
    this._loadAppInsights();

    if (!this._loadedApplicationInsights) {
      console.info('stopTrackPage:', name, customProperties);
      return;
    }
    this._loadedApplicationInsights.stopTrackPage(name, undefined, customProperties);
  }

  public trackPageView(name: string, customProperties?: PageViewFnArguments[1]) {
    this._loadAppInsights();

    if (!this._loadedApplicationInsights) {
      console.info('trackPageView:', name, customProperties);
      return;
    }

    const pageView: PageViewFnArguments[0] = {
      name,
    };
    this._loadedApplicationInsights.trackPageView(pageView, customProperties);
  }

  public forceSendEvents() {
    this._loadAppInsights();
    if (!this._loadedApplicationInsights) {
      console.info('forceSendEvents failed');
      return;
    }
    this._loadedApplicationInsights.flush();
  }

  public static createInstance() {
    if (!TelemetryService.Instance) {
      TelemetryService.Instance = new TelemetryService();
    }

    return TelemetryService.Instance;
  }

  public setAuthenticatedUser(id: string): void {
    this._loadAppInsights();
    if (!this._loadedApplicationInsights) return;
    this._loadedApplicationInsights.setAuthenticatedUserContext(id);
  }

  public getSessionId() {
    this._loadAppInsights();

    if (this._loadedApplicationInsights) {
      return this._loadedApplicationInsights.context.getSessionId();
    }

    return this._sessionId;
  }

  createNewSession(): void {
    if (!this._loadedApplicationInsights) return;
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    (<any>this._loadedApplicationInsights.context).sessionManager.automaticSession.id = uuidv4();
  }
}

export const ai = TelemetryService.createInstance();
