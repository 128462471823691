import handleRestError from 'src/redux/requests/nexus/handleRestError';
import currentProfileProvider from 'src/utils/CurrentProfileProvider/CurrentProfileProvider';
import store from 'src/redux/store';
import { FetchApiException } from 'src/requests/exceptions/fetchApiException';
import { requestWithRetry } from 'src/requests/withRetryWrapper';
import { v4 as uuidv4 } from 'uuid';
import { ai } from '@sekoia/shared/utils/telemetryService';
import { Authentication } from 'src/utils/authentication/Authentication';

const parseBody = (body, profile) => {
  Object.keys(body).forEach((key) => {
    body[key] = parseStringVariables(body[key], profile);
  });
  return body;
};

const parseStringVariables = (url, profile) => {
  if (typeof url !== 'string') return url;

  if (profile && profile.customerId) {
    return url.replace('[[customerId]]', profile.customerId);
  }
  return url;
};

async function fetchData(request) {
  const profile = currentProfileProvider.Instance.getCurrentProfile();
  const token = await Authentication.Instance.getToken();

  let headers = new Headers();

  headers.set('content-type', 'application/json');
  headers.set('Accept', 'application/json');
  headers.set('sekoia.session_id', ai.getSessionId());
  headers.set('sekoia.correlation_id', uuidv4());
  headers.set('sekoia.customer_id', profile.customerId);
  headers.set('Authorization', `Bearer ${token}`);

  const options = {
    method: request.method,
    headers: headers,
  };

  if (request.body) {
    const parsedBody = parseBody(request.body, profile);
    options.body = JSON.stringify(parsedBody);
  }

  const urlWithCustomerId = request.url.replace('[[customerId]]', profile.customerId);
  const requestUrl = isUrlAbsolute(urlWithCustomerId) ? urlWithCustomerId : profile.customerUrl + urlWithCustomerId;

  const response = await fetch(requestUrl, options);
  let jsonResponse = {};
  try {
    jsonResponse = await response.json();
  } catch (error) {}

  if (response.status && response.status >= 200 && response.status <= 299) {
    return jsonResponse;
  } else {
    throw new FetchApiException(response.statusText, response.status, '', response.headers, '');
  }
}

async function wrapper(func, httpStatusCodeFilter = null) {
  let response;
  try {
    response = await func();
  } catch (error) {
    if (
      httpStatusCodeFilter &&
      httpStatusCodeFilter instanceof StatusCodeRethrowFilter &&
      httpStatusCodeFilter.shouldThrow(error)
    ) {
      throw error;
    }

    store.dispatch(handleRestError(error));
  }

  return response;
}

function isUrlAbsolute(url) {
  const pattern = /^https?:\/\//i;

  return pattern.test(url);
}

export async function executeWithRetry(request, httpStatusCodeFilter = null, maxAttempts = 3) {
  return await requestWithRetry(async () => await fetchData(request), httpStatusCodeFilter, maxAttempts);
}

export async function execute(request, httpStatusCodeFilter = null) {
  return await wrapper(async () => await fetchData(request), httpStatusCodeFilter);
}

export class StatusCodeRethrowFilter {
  constructor(retrowOnAllErrors = false, retrowOnSpecificStatusCodes = []) {
    this.retrowAllErrors = retrowOnAllErrors;
    this.retrowOnSpecificStatusCodes = retrowOnSpecificStatusCodes;
  }

  shouldThrow(error) {
    if (this.retrowAllErrors) {
      return true;
    }

    if (!this.retrowOnSpecificStatusCodes) {
      return false;
    }

    return this.containsHttpStatusCodes(error, this.retrowOnSpecificStatusCodes);
  }

  containsHttpStatusCodes(error, httpStatusCodeFilter) {
    return Array.isArray(httpStatusCodeFilter) && httpStatusCodeFilter.includes(error.status);
  }
}
