import React, { PureComponent } from 'react';
import withStyles from '@mui/styles/withStyles';
import PinDots from 'src/react/Pin/Dots/PinDots';
import PinNumpad from 'src/react/Pin/Numpad/PinNumpad';
import styles from 'src/react/Pin/View/PinView.styles';
import Grid from '@mui/material/Grid';
import { ModalDialog } from 'src/react/_Layout/ModalDialog';
import { DialogContent } from '@mui/material';
import PinAvatar from 'src/react/Pin/Avatar/PinAvatar';
import { isMobile } from 'react-device-detect';
import { withTranslation } from 'react-i18next';
import Typography from 'src/react/_Shared/Typography/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { dialogContentActionButtonZIndex } from 'src/utils/zIndexConstants';
import LogoutDialog from 'src/react/_Layout/LogoutDialog';

@withTranslation(['pin'])
@withStyles(styles, { withTheme: true })
export default class PinView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showLogoutDialog: false,
    };
  }

  closeHandler = () => {
    const { closeHandler } = this.props;
    if (closeHandler) closeHandler();
  };

  closeLogoutDialog = () => {
    this.setState({ showLogoutDialog: false });
  };
  openLogoutDialog = () => {
    this.setState({ showLogoutDialog: true });
  };

  renderPinDots() {
    const { classes } = this.props;

    return (
      <div className={classes.dots}>
        <PinDots
          unfilledColor={this.props.theme.palette.grey[300]}
          filledColor={this.props.theme.palette.grey[300]}
          pinEntered={this.props.value}
          size={40}
          appearance={classes}
        />
      </div>
    );
  }

  render() {
    const {
      classes,
      wrongPinEntered,
      t,
      customZindex,
      avatarPicUrl,
      showChangeUserBtn,
      showGoBackBtn = false,
    } = this.props;

    return (
      <ModalDialog zIndexSet={customZindex && customZindex} open={true} appBarType={'none'} formDialog={false}>
        <DialogContent classes={{ root: classes.root }} style={{ padding: 0 }}>
          {showGoBackBtn && (
            <IconButton
              size="medium"
              onClick={this.closeHandler}
              style={{
                position: 'absolute',
                zIndex: dialogContentActionButtonZIndex,
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
          <div className={classes.dialogContent}>
            <Grid container style={{ height: '100%', overflowX: 'hidden' }} justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                <div style={{ textAlign: 'center' }}>
                  <img style={{ maxWidth: 210 }} src="images/Sekoia_Logo.svg" />
                </div>
                <PinAvatar avatarPicUrl={avatarPicUrl} name={this.props.name} />
                {showChangeUserBtn && (
                  <div style={{ textAlign: 'center' }}>
                    <Button size="small" color="primary" onClick={this.openLogoutDialog}>
                      {t('pin:changeUser')}
                    </Button>
                  </div>
                )}
                {wrongPinEntered === 2 && (
                  <Typography style={{ color: 'red', display: 'flex', justifyContent: 'center' }} variant="body2">
                    {t('pin:lastRetry')}
                  </Typography>
                )}
                <div style={{ paddingTop: 16 }}>{this.renderPinDots()}</div>
                {!isMobile && (
                  <div style={{ paddingTop: 32 }} className={classes.numpadContainer}>
                    <PinNumpad
                      clickHandler={this.props.onAddToCode}
                      appearance={classes}
                      deleteEntry={this.props.onDelete}
                    />
                  </div>
                )}
                {showChangeUserBtn && (
                  <LogoutDialog open={this.state.showLogoutDialog} onClose={this.closeLogoutDialog} />
                )}
              </Grid>
              {isMobile && (
                <Grid
                  className={classes.numpadContainer}
                  style={{
                    paddingBottom: window.innerHeight < 340 ? 24 : 0,
                  }}
                  item
                  xs={12}
                >
                  <PinNumpad
                    clickHandler={this.props.onAddToCode}
                    appearance={classes}
                    deleteEntry={this.props.onDelete}
                  />
                </Grid>
              )}
            </Grid>
          </div>
        </DialogContent>
      </ModalDialog>
    );
  }
}
