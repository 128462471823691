import CurrentUserProvider from 'src/utils/CurrentProfileProvider/CurrentProfileProvider';
import moment from 'moment-timezone';

export const getTimeZone = () => {
  let timeZone = moment.tz.guess();
  const cultureInfoCode = CurrentUserProvider.Instance.getCurrentProfile()?.cultureInfoCode;
  switch (cultureInfoCode) {
    case 'da-DK':
      timeZone = 'Europe/Copenhagen';
      break;
    case 'en-GB':
      timeZone = 'Europe/London';
      break;
    case 'sv-SE':
      timeZone = 'Europe/Stockholm';
      break;
    case 'nl-NL':
      timeZone = 'Europe/Amsterdam';
      break;
    default:
      break;
  }
  return timeZone;
};

export const getMomentInCustomerTimeZone = (momentObj: moment.Moment) => {
  const customerTimeZone = getTimeZone();
  return moment.tz(momentObj, customerTimeZone);
};
