import * as types from 'src/redux/constants';

export const deviceResidentId = (value) => ({
  type: types.DEVICE_RESIDENT_ID,
  payload: {
    value,
  },
});

export const setSelectedFiltersToDeviceResident = (value) => ({
  type: types.SET_SELECTED_FILTERS_TO_DEVICE_RESIDENT,
  payload: {
    value,
  },
});

export const deviceApartmentId = (value) => ({
  type: types.DEVICE_APARTMENT_ID,
  payload: {
    value,
  },
});

export const deviceChosenResidentIds = (value) => ({
  type: types.DEVICE_CHOSEN_RESIDENT_IDS,
  payload: {
    value,
  },
});
