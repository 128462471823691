import moment from 'moment-timezone';
import { ai } from '@sekoia/shared/utils/telemetryService';

export const isSessionActive = (): boolean => {
  const sessionExpiresAt = localStorage.getItem('sessionExpiresAt');
  if (sessionExpiresAt) {
    const sessionExpiresAtTimestamp = parseInt(sessionExpiresAt);
    if (Number.isNaN(sessionExpiresAtTimestamp)) {
      ai.trackTrace(
        `sessionValidation.ts, isSessionActive: Could not parse expiration time from storage: '${sessionExpiresAt}'.`,
      );

      return false;
    }
    const threshold = 15;
    const sessionExpiresAtMoment = moment.unix(sessionExpiresAtTimestamp);
    const diff = moment().diff(sessionExpiresAtMoment, 'seconds');
    const sessionIsValid = diff < threshold;
    return sessionIsValid;
  }

  return false;
};
