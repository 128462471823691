import { User, UserManager, UserManagerEvents, UserManagerSettings, UserSettings } from 'oidc-client';
import PinManager from 'src/react/Pin/PinManager';
import JWTTokenHelper, { IdDecodedJWT } from 'src/utils/authentication/jwtTokenHelper';
import loginServiceUrl from 'src/utils/authentication/loginServiceUrl';
import { isSessionActive } from 'src/utils/authentication/sessionValidation';
import currentProfileProvider from 'src/utils/CurrentProfileProvider/CurrentProfileProvider';
import { getSessionId } from 'src/utils/sessionId';
import { ai } from '@sekoia/shared/utils/telemetryService';
import { updateClarityIdentity } from '@sekoia/shared/helpers/clarity';
import { v4 as uuidv4 } from 'uuid';
import 'whatwg-fetch';
import { DeviceInfoManager } from '@sekoia/shared/utils/DeviceInfoManager';
import { getSignedMessage } from '../nativeCommunication';
import { ResidentAuthentication } from 'src/utils/authentication/ResidentAuthentication';
import UserManagerSettingsResident from 'src/utils/authentication/userManagerSettings/UserManagerSettingsResident';

export type TokenProfileInfo = {
  globalId: string;
  legacyId: number;
  roles: string[] | string;
  customerId: string;
  compassUrl: string;
  cultureInfoCode: string;
  accessControlTags: string[];
  loginType?: string;
};

export enum LoginType {
  Password = 'Password',
  Pin = 'Pin',
  AD = 'AD',
  Location = 'Location',
  Resident = 'Resident',
}

export class Authentication {
  public static Instance: Authentication;

  public userManager: UserManager;
  private pinManager: PinManager = new PinManager();
  private _jwtTokenDecoder: JWTTokenHelper = new JWTTokenHelper();

  constructor(settings: UserManagerSettings) {
    this.userManager = new UserManager(settings);
    this.userManager.events.addSilentRenewError(this.logSilentRenewError);

    this.userManager.events.addUserLoaded(async (user: User) => {
      if (user) {
        if (user.refresh_token) {
          localStorage.setItem('refresh_token', user.refresh_token);
        }
        if (user.id_token) {
          localStorage.setItem('id_token', user.id_token);
        }
      }
    });
  }

  public async getProfileInformationFromToken(): Promise<TokenProfileInfo | null> {
    const token = await this.getToken();

    if (!token) {
      return null;
    }

    const {
      nameid: globalId,
      unique_name: legacyId,
      role: roles,
      'sekoia:CustomerId': customerId,
      compassUrl,
      cultureInfoCode,
      'sekoia:AccessControlTags': accessControlTagsRaw,
      'sekoia:loginType': loginType,
    } = this._jwtTokenDecoder.decodeToken(token);

    ai.setAuthenticatedUser(globalId);
    updateClarityIdentity(globalId);

    const accessControlTags = Array.isArray(accessControlTagsRaw)
      ? accessControlTagsRaw
      : accessControlTagsRaw
      ? [accessControlTagsRaw]
      : [];

    return {
      globalId,
      legacyId: parseInt(legacyId),
      roles,
      customerId,
      compassUrl,
      cultureInfoCode,
      accessControlTags,
      loginType,
    };
  }

  public static createInstance(settings: UserManagerSettings): void {
    Authentication.Instance = new Authentication(settings);
    if (settings.automaticSilentRenew) {
      Authentication.Instance.userManager.events.addSilentRenewError(() => {
        const isResidentDevice = settings.client_id === UserManagerSettingsResident().client_id;
        if (isResidentDevice) {
          ResidentAuthentication.Instance.initiateResidentLogin();
        } else {
          Authentication.Instance.logout();
        }
      });
    }
  }

  public login(acrValues?: string): Promise<void> {
    this.userManager.clearStaleState();
    ai.trackTrace('authentication login', Authentication.getAILogProperties());

    return this.userManager.signinRedirect({
      acr_values: acrValues,
    });
  }

  private static getAILogProperties() {
    return {
      deviceId: DeviceInfoManager.Instance.getDeviceId(),
      deviceCustomerId: DeviceInfoManager.Instance.getCustomerId(),
      sessionExpiresAt: localStorage.getItem('sessionExpiresAt'),
    };
  }

  public async getRolesOfCurrentUser(): Promise<string[]> {
    const profile = await this.userManager.getUser();

    if (!profile) {
      return [];
    }

    let currentProfileRoles = this._jwtTokenDecoder.decodeToken(profile.access_token)['role'];
    if (!Array.isArray(currentProfileRoles)) {
      currentProfileRoles = [currentProfileRoles];
    }
    return currentProfileRoles.filter((role) => !parseInt(role));
  }

  public async authenticate(): Promise<void> {
    const url = window.location.hash.replace('#/', '#');

    const profile = await this.userManager.signinRedirectCallback(url);

    const decodedToken = this._jwtTokenDecoder.decodeToken(profile.access_token);
    const sessionExpiresAt = decodedToken['client_session_expires_at'];

    if (sessionExpiresAt) {
      localStorage.setItem('sessionExpiresAt', sessionExpiresAt);
    }

    const result = await this.setCurrentProfile(profile.access_token);
    await this.pinManager.extendProfile();
    if (!result) {
      await this.logout();
    } else {
      window.location.replace(this.userManager.settings.redirect_uri ?? '');
    }
  }

  public async getLegacyUserId(): Promise<number | null> {
    const token = await this.getToken();
    if (!token) {
      return null;
    }

    const decodedToken = this._jwtTokenDecoder.decodeToken(token);
    return parseInt(decodedToken['unique_name']);
  }

  private async setCurrentProfile(accessToken: string): Promise<boolean> {
    const {
      'sekoia:CustomerId': customerId,
      compassUrl,
      cultureInfoCode,
      nameid: profileId,
    } = this._jwtTokenDecoder.decodeToken(accessToken);

    localStorage.setItem('customerId', customerId);

    return await currentProfileProvider.Instance.setCurrentProfile(profileId, customerId, compassUrl, cultureInfoCode);
  }

  public get customerId() {
    return localStorage.getItem('customerId') || '';
  }

  public async isAuthenticated(): Promise<boolean> {
    const user = await this.userManager.getUser();
    const loggedIn = !(!user || !user.access_token) && !user.expired;
    if (loggedIn && user?.access_token && !currentProfileProvider.Instance.getCurrentProfile()) {
      await this.setCurrentProfile(user.access_token);
    }
    return loggedIn;
  }

  public isSessionActive(): boolean {
    return isSessionActive();
  }

  public async getToken() {
    const user = await this.userManager.getUser();
    if (!user) {
      ai.trackTrace(`Authentication.ts, GetToken: No user present to return access token from. `);
      return null;
    }

    return user.access_token;
  }

  public async logoutKeepingStorageSettings() {
    ai.trackTrace('logoutKeepingStorageSettings', Authentication.getAILogProperties());

    await Authentication.Instance.userManager.removeUser();
  }

  private async oldLogout(): Promise<void> {
    if (!this.userManager.settings.post_logout_redirect_uri) {
      ai.trackException({
        exception: new Error(`Usermanager setting does not contain a post_logout_redirect_uri`),
      });
      window.location.reload();
      return;
    }
    const signoutUrl = await this.userManager.createSignoutRequest();

    if (signoutUrl) {
      await fetch(signoutUrl.url, {
        credentials: 'include',
        headers: { 'sekoia.session_id': getSessionId() },
      });
    }

    this.userManager.clearStaleState();
    try {
      await this.userManager.removeUser();
    } catch (error) {
      ai.trackException({
        exception: error as Error,
        properties: { message: 'Error removing user from OIDC managers' },
      });
      window.sessionStorage.clear();
    }
    ai.forceSendEvents();
    window.location.replace(this.userManager.settings.post_logout_redirect_uri);
  }

  public async logout(): Promise<void> {
    ai.trackTrace('authentication logout', Authentication.getAILogProperties());

    const args = await this.getLogoutArguments(localStorage.getItem('id_token'));

    localStorage.removeItem('sessionExpiresAt');
    localStorage.removeItem('customerId');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('id_token');
    await this.getLegacyUserId().then((id) => {
      sessionStorage.removeItem(`${id}-taskScrollOffset`);
      sessionStorage.removeItem(`${id}-showHandledTasks`);
      sessionStorage.removeItem(`${id}-tasksShowForYesterday`);
      sessionStorage.removeItem(`${id}-tasksShowForTomorrow`);
    });

    Authentication.Instance.userManager.stopSilentRenew();
    currentProfileProvider.Instance.clearCurrentProfile();

    if (!('id_token_hint' in args)) {
      await this.oldLogout();
      return;
    }

    window.sessionStorage.clear();

    this.userManager.clearStaleState();
    try {
      await this.userManager.removeUser();
    } catch (error) {
      ai.trackException({
        exception: error as Error,
        properties: { message: 'Error removing user from OIDC managers' },
      });
    }

    ai.forceSendEvents();
    await Authentication.Instance.userManager.signoutRedirect(args);
  }

  public async pinSignin(pinToCheck: string): Promise<number> {
    const url = `${loginServiceUrl()}/pinlogin`;

    const options: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'sekoia.session_id': getSessionId(),
        'sekoia.correlation_id': uuidv4(),
      },
      body: JSON.stringify({
        pin: pinToCheck,
        clientId: this.userManager.settings.client_id,
        refreshToken: localStorage.getItem('refresh_token'),
      }),
    };

    try {
      const response: Response = await fetch(url, options);

      if (response.status === 200) {
        const responseJson = await response.json();
        let user = await this.userManager.getUser();
        if (!user) {
          const id_token = this._jwtTokenDecoder.decodeToken<IdDecodedJWT>(localStorage.getItem('id_token') ?? '');
          user = new User({
            scope: this.userManager.settings.scope,
            token_type: 'Bearer',
            profile: {
              sub: id_token['sub'],
              auth_time: id_token['auth_time'],
            },
          } as UserSettings);
        }
        user.refresh_token = responseJson.refreshToken;
        await this.userManager.storeUser(user);
        ai.createNewSession();
        updateClarityIdentity(user.profile.sub);
      }
      return response.status;
    } catch (e) {
      ai.trackException(e, `Error in PIN login, to URL '${url}'`);

      return 401; // Indicate no access
    }
  }

  public async authenticateColleague(residentOrganizationalId: string, colleagueId: number, pin: string) {
    const url = `${loginServiceUrl()}/pinlogin/colleague`;
    const token = await this.getToken();

    const options: RequestInit = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'Application/json',
        Accept: 'Application/json',
        'sekoia.session_id': getSessionId(),
      },
      body: JSON.stringify({
        residentOrganisationalId: residentOrganizationalId,
        userId: colleagueId,
        pin: pin,
      }),
    };

    const response = await fetch(url, options);
    return response.status;
  }

  public async karunaSignin(token: string) {
    this.writeAuthValuesToSessionStorage(token);

    await this.setCurrentProfile(token);
  }

  public async signinSilent(): Promise<User> {
    return await this.userManager.signinSilent();
  }

  public addUserLoaded(callBack: UserManagerEvents.UserLoadedCallback): void {
    this.userManager.events.addUserLoaded(callBack);
  }

  public removeUserLoaded(callBack: UserManagerEvents.UserLoadedCallback): void {
    this.userManager.events.removeUserLoaded(callBack);
  }

  private writeAuthValuesToSessionStorage(accessToken: string) {
    const decodedToken = this._jwtTokenDecoder.decodeToken(accessToken);
    const expiresAt = decodedToken['exp'];
    const tokenType = 'urn:ietf:params:oauth:token-type:jwt';
    const tokenObject = {
      access_token: accessToken,
      token_type: tokenType,
      expires_at: expiresAt,
    };

    const sessionStorageItem = 'oidc.user:dummy/issue/oauth2/:' + this.userManager.settings.client_id;

    sessionStorage.removeItem(sessionStorageItem);
    sessionStorage.setItem(sessionStorageItem, JSON.stringify(tokenObject, null, ''));
  }

  private async getLogoutArguments(id_token: string | null) {
    const deviceId = DeviceInfoManager.Instance.getDeviceId();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let args: any = {};
    if (deviceId) {
      try {
        const salt = uuidv4();
        const deviceIdSigned = await getSignedMessage(deviceId + salt);
        args = {
          extraQueryParams: {
            deviceId: deviceId,
            deviceIdSigned: deviceIdSigned,
            salt: salt,
            customerId: this.customerId,
          },
        };
      } catch {}
    }
    if (id_token && id_token !== 'undefined') {
      args = { id_token_hint: id_token, ...args, extraQueryParams: { idToken: id_token, ...args.extraQueryParams } };
      ai.trackEvent('id_token present', {
        args,
      });
    }
    return args;
  }

  private logSilentRenewError(error: Error) {
    ai.trackException(error, `Could not renew the session, with silent refresh.`);
  }
}
