import React from 'react';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBack from '@mui/icons-material/ArrowBack';
import SwipeBack from 'src/react/_Layout/SwipeBack';
import { modalDialogCloseButtonZIndex } from 'src/utils/zIndexConstants';
import { COLORS } from 'src/react/_Layout/StyleConstants';
import { useHistory } from 'react-router';
import { makeStyles } from '@mui/styles';
import { TransitionDown, TransitionLeft, TransitionRight, TransitionUp } from '@sekoia/shared/components/Transitions';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: 'white',
    color: COLORS.greyText,
    borderBottomStyle: 'solid',
    borderBottomColor: COLORS.greyText,
    borderBottomWidth: 1,
  },
  appBarTitle: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flex: 1,
  },
  dialogContent: {
    overflowScrolling: 'touch',
    WebkitOverflowScrolling: 'touch',
    overflowY: 'auto',
    position: 'absolute',
    top: 48,
    bottom: 0,
    left: 0,
    right: 0,
  },
  fullScreenShowCloseBtn: {
    left: 0,
    position: 'absolute',
    color: 'gray',
    mixBlendMode: 'difference',
    zIndex: modalDialogCloseButtonZIndex,
  },
  secondaryActionArea: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    maxWidth: theme.spacing(6),
  },
  secondaryActionAreaLabel: {
    paddingRight: theme.spacing(2),
  },
}));

type ModalDialogProps = {
  open: boolean;
  formDialog?: boolean;
  title?: string | null | React.ReactElement;
  dialogHeight?: string;
  appBarType?: 'regular' | 'closeAndBackButton' | 'closeButton' | 'none';
  closeAsLabel?: boolean;
  handler?: () => void;
  customGoBack?: () => void;
  actionButtons?: React.ReactElement;
  transition?: 'up' | 'down' | 'left' | 'right';
  zIndexSet?: number;
  backgroundColor?: string;
};

export const ModalDialog: React.FC<React.PropsWithChildren<ModalDialogProps>> = (props) => {
  const {
    title,
    handler,
    customGoBack,
    appBarType = 'regular',
    closeAsLabel = false,
    formDialog = true,
    open,
    actionButtons,
    dialogHeight,
    transition,
    zIndexSet,
    backgroundColor,
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation(['global']);

  const handleClose = () => {
    history.goBack();
  };

  const renderAppBar = () => {
    switch (appBarType) {
      case 'regular':
        return (
          <AppBar className={classes.appBar} elevation={0}>
            <Toolbar variant="dense" disableGutters>
              <IconButton color="inherit" onClick={handler || handleClose} aria-label="Close" size="large">
                <CloseIcon />
              </IconButton>
              <Typography variant="subtitle1" color="inherit" className={classes.appBarTitle}>
                {title}
              </Typography>
              {actionButtons}
            </Toolbar>
          </AppBar>
        );
      case 'closeAndBackButton':
        return (
          <AppBar className={classes.appBar} elevation={0}>
            <Toolbar variant="dense" disableGutters>
              <IconButton color="inherit" onClick={customGoBack || handleClose} aria-label="Close" size="large">
                <ArrowBack />
              </IconButton>
              <Typography variant="subtitle1" color="inherit" className={classes.appBarTitle} style={{ width: '100%' }}>
                {title}
              </Typography>
              <div className={closeAsLabel ? classes.secondaryActionAreaLabel : classes.secondaryActionArea}>
                {!closeAsLabel && (
                  <IconButton color="inherit" onClick={handler || handleClose} aria-label="Close" size="large">
                    <CloseIcon />
                  </IconButton>
                )}
                {closeAsLabel && (
                  <Typography
                    variant="subtitle1"
                    color="inherit"
                    className={classes.appBarTitle}
                    onClick={handler || handleClose}
                    style={{ width: '100%', cursor: 'pointer' }}
                  >
                    {t('actions.close')}
                  </Typography>
                )}
              </div>
            </Toolbar>
          </AppBar>
        );
      case 'closeButton':
        return (
          <IconButton
            size="medium"
            classes={{ root: classes.fullScreenShowCloseBtn }}
            color="inherit"
            onClick={handler || handleClose}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        );
      case 'none':
        return null;
      default:
        return null;
    }
  };

  const renderMarginBottomBox = () => {
    if (!formDialog) return null;
    const height = window.innerHeight;
    return <div style={{ height: height }}>&nbsp;</div>;
  };

  const renderTransition = () => {
    if (!transition) return undefined;
    switch (transition) {
      case 'up':
        return TransitionUp;
      case 'down':
        return TransitionDown;
      case 'left':
        return TransitionLeft;
      case 'right':
        return TransitionRight;

      default:
        return undefined;
    }
  };

  return (
    <Dialog
      disableEscapeKeyDown={true}
      fullScreen={true}
      open={open}
      TransitionComponent={renderTransition()}
      style={{ zIndex: zIndexSet }}
    >
      {renderAppBar()}
      <div
        className={classes.dialogContent}
        style={{
          backgroundColor: backgroundColor && backgroundColor,
        }}
      >
        <div
          style={{
            maxWidth: formDialog ? 600 : 'unset',
            height: dialogHeight ? dialogHeight : 'unset',
          }}
        >
          {props.children}
          {renderMarginBottomBox()}
        </div>
      </div>
      {appBarType === 'closeAndBackButton' && <SwipeBack handler={customGoBack || handleClose} />}
    </Dialog>
  );
};
