import { useContext } from 'react';
import CustomerInfoContext from 'src/context/CustomerInfo/CustomerInfoContext';
import { MarkAllRequired } from 'src/utils/TypeScriptHelpers';

export const useCustomerInfo = () => {
  const customerInfo = useContext(CustomerInfoContext);

  if (!customerInfo.customerUrl || !customerInfo.customerId) {
    throw Error(`Could not find any customerInfo, did you forget to wrap with <CustomerInfoProvider />?`);
  }

  // We mark everything required, so TypeScript does not complain when using this hook.
  // The above check, should ensure everything is set
  return customerInfo as MarkAllRequired<CustomerInfoContext>;
};
