import { fromJS } from 'immutable';
import * as types from 'src/redux/constants';

const initialState = fromJS({});

export default function cacheReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case types.DIRECTORY_SEED: {
      payload.data.forEach((item) => {
        state = state.setIn([item.node.type, item.id], fromJS(item));
      });
      return state;
    }
    case types.DIRECTORY_SET_IS_RESIDENT_ACTIVE: {
      state = state.updateIn(['Citizen', payload.residentId, 'node', 'isActive'], () => payload.isActive);
      return state;
    }
    default:
      return state;
  }
}
