import { fromJS, isImmutable } from 'immutable';
import * as types from 'src/redux/constants';
import moment from 'moment-timezone';
import { getTimeZone } from 'src/helpers/timeZoneHelper';
const initialState = fromJS({});

export default function cacheReducer(state = initialState, action) {
  const { payload, type } = action;
  let timeZone = getTimeZone();

  const parsePayloadData = (obj) => {
    if (payload.path[0] === 'tasks' && payload.path[1] === 'organizations') {
      let parsedData = obj;
      var lastChangedEvent = obj.events?.sort(function (left, right) {
        return moment(left.timestamp) > moment(right.timestamp);
      });
      parsedData = {
        id: obj.id,
        citizenId: obj.residentId,
        type: 'task',
        delegatedProffessionId: obj.delegatedProfessionId ? obj.delegatedProfessionId : '0',
        categoryIds: obj.standardTaskInformation.categoryIds,
        plannedExecutionSlot: {
          after: moment.tz(obj.intervalStartTime, timeZone).format('YYYY-MM-DDTHH:mm:ss'),
          before: moment.tz(obj.intervalEndTime, timeZone).format('YYYY-MM-DDTHH:mm:ss'),
          timeZone: timeZone,
        },
        associatedNexusVumSubGoalIds: obj.standardTaskInformation.associatedNexusVumSubGoalIds,
        associatedPlanIds: obj.standardTaskInformation.associatedPlanIds,
        description: obj.description,
        professionId: obj.professionId,
        state: obj.taskState,
        movedState: movedState(obj),
        requiresTwoWorkers: obj.isTwoPersonTask,
        isMedicationRelated: obj.isMedicationRelated,
        isContactPersonRelated: obj.isContactRelated,
        taskEntryId: obj.taskEntryId,
        isAdHoc: obj.isCreatedAdHoc,
        isResidentTask: obj.isResidentTask,
        title: obj.title,
        assignedAtTimestamp: obj.assignedAtTimestamp,
        assignedByEmployeeId: obj.assignedByEmployeeId,
        lastUpdatedAtTimestamp: obj.lastUpdatedAtTimestamp,
        standardTaskId: obj.standardTaskId,
        originalTaskAssignmentId: obj.originalTaskAssignmentId,
        lastChangedEvent: lastChangedEvent,
        uniqueId: `${obj.id}_-_${obj.residentId}`,
      };
      return parsedData;
    }
    return obj;
  };

  switch (type) {
    case types.CACHE_SAVE: {
      // Ensure that arrays gets converted to { id: obj } instead
      if (Array.isArray(payload.data) && payload.groupBy) {
        state = state.deleteIn(payload.path);
        payload.data.forEach((obj) => {
          const payloadPath = payload.path.concat('' + obj[payload.groupBy]);
          let parsedData = parsePayloadData(obj);
          if (payload.path[0] === 'tasks' && payload.path[1] === 'organizations') {
            parsedData = parsePayloadData(obj);
          }
          state = state.setIn(payloadPath, fromJS(parsedData));
        });
      } else if (typeof payload.data === 'object') {
        state = state.setIn(payload.path, fromJS(parsePayloadData(payload.data)));
      } else {
        state = state.setIn(payload.path, payload.data);
      }
      return state;
    }
    case types.CACHE_CLEAR: {
      return state.deleteIn(payload.path);
    }

    case types.SET_TASK_OPTIMISTIC: {
      const { organizationId, departmentId, taskId, lastChangedEvent, state: taskState, movedState } = action.payload;

      state = state.setIn(
        ['tasks', 'organizations', organizationId, 'departments', departmentId, taskId, 'state'],
        fromJS(taskState),
      );
      state = state.setIn(
        ['tasks', 'organizations', organizationId, 'departments', departmentId, taskId, 'lastChangedEvent'],
        fromJS(lastChangedEvent),
      );
      state = state.setIn(
        ['tasks', 'organizations', organizationId, 'departments', departmentId, taskId, 'movedState'],
        fromJS(movedState),
      );

      return state;
    }
    case types.CLEAR_TASK_OPTIMISTIC: {
      const { organizationId, departmentId, task } = action.payload;

      const taskIsImmutable = isImmutable(task);

      const taskState = taskIsImmutable ? task.get('state') : task.state;
      const taskLastChangedEvent = taskIsImmutable ? task.get('lastChangedEvent') : task.lastChangedEvent;
      const taskMovedState = taskIsImmutable ? task.get('movedState') : task.movedState;
      const taskId = taskIsImmutable ? task.get('id') : task.id;

      state = state.setIn(
        ['tasks', 'organizations', organizationId, 'departments', departmentId, taskId, 'state'],
        taskState,
      );
      state = state.setIn(
        ['tasks', 'organizations', organizationId, 'departments', departmentId, taskId, 'lastChangedEvent'],
        taskLastChangedEvent,
      );
      state = state.setIn(
        ['tasks', 'organizations', organizationId, 'departments', departmentId, taskId, 'movedState'],
        taskMovedState,
      );

      return state;
    }

    default:
      return state;
  }
}

const movedState = (item) => {
  const moved = item.moved;
  const movedHere = item.movedHere;
  if (!moved && !movedHere) return 'NotMoved';
  if (!moved && movedHere) return 'MovedHere';
  if (moved && !movedHere) return 'MovedAway';
  if (moved && movedHere) return 'MovedHereAndAway';
};
