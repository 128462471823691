import { fromJS } from 'immutable';
import * as types from 'src/redux/constants';

const initialState = fromJS({
  deviceResidentId: null,
  setSelectedFiltersToDeviceResident: true,
  deviceApartmentId: null,
  deviceChosenResidentIds: [],
});

export default function deviceSettings(state = initialState, action) {
  switch (action.type) {
    case types.DEVICE_RESIDENT_ID:
      const deviceResidentId = action.payload.value;
      return state.set('deviceResidentId', deviceResidentId);

    case types.SET_SELECTED_FILTERS_TO_DEVICE_RESIDENT:
      return state.set('setSelectedFiltersToDeviceResident', action.payload.value);

    case types.DEVICE_APARTMENT_ID:
      return state.set('deviceApartmentId', action.payload.value);

    case types.DEVICE_CHOSEN_RESIDENT_IDS:
      return state.set('deviceChosenResidentIds', action.payload.value);

    default:
      return state;
  }
}
