export const MENU_LIST_MAX_WIDTH = 426;
export const BOTTOM_NAVIGATION_HEIGHT = 56;
export const APP_BAR_HEIGHT = 48;
export const TASK_FILTER_CHIPS_HEIGHT = 48;
export const RESIDENT_TASK_LIST_ROW_HEIGHT = 92;
export const RESIDENT_LIST_TILE_HEIGHT = 76;
export const DATE_RANGE_PICKER_HEIGHT = 96;
export const OBSERVATIONS_TAB_HEIGHT = 48;
export const RESIDENT_TOOLBAR = 48;
export const HEADER_BAR_HEIGHT = 64;
export const TASK_ITEM_HEIGHT = 92;
export const TASK_ITEM_HEIGHT_WITH_SSN = 108;
export const COLORS = {
  primary: {
    dark: '#29434e',
    light: '#819ca9',
    main: '#546e7a',
    mainRGB: 'rgba(84, 110, 122)',
    contrastText: '#ffffff',
  },
  secondary: {
    contrastText: '#fff',
    lightBlue: '#00b3df',
    dark: '#005005',
    light: '#60ad5e',
    main: '#2e7d32',
  },
  reverse: {
    hover: '#e8edef',
  },
  positive: '#4aa573',
  grayBackground: '#f5f5f5',
  grayDivider: 'rgba(0, 0, 0, 0.12)',
  greyText: 'rgba(0, 0, 0, 0.70)',
  grayButtons: 'rgba(0, 0, 0, 0.54)',
  disabled: 'rgba(0, 0, 0, 0.38)',
  overdue: '#E65100',
  pageLock: 'rgba(255,255,255,0.80)',
  avatarBackground: '#546f7a',
  contrastTable: {
    0: { background: '#00A0DB', foreground: '#FFF', toned: '#b2e2f4', tonedFont: '#59899b' },
    1: { background: '#F5C123', foreground: '#FFF', toned: '#fcecbd', tonedFont: '#a39363' },
    2: { background: '#FA5725', foreground: '#FFF', toned: '#fdccbd', tonedFont: '#a47364' },
    3: { background: '#E4C342', foreground: '#FFF', toned: '#f7edc6', tonedFont: '#9d946d' },
    4: { background: '#A41B55', foreground: '#FFF', toned: '#e4bacc', tonedFont: '#8a6172' },
    5: { background: '#058145', foreground: '#FFF', toned: '#b4d9c7', tonedFont: '#5a806d' },
    6: { background: '#4052AE', foreground: '#FFF', toned: '#c5cbe7', tonedFont: '#6c718d' },
    7: { background: '#8C25A9', foreground: '#FFF', toned: '#dcbde5', tonedFont: '#83648c' },
    8: { background: '#DB1A60', foreground: '#FFF', toned: '#f4bacf', tonedFont: '#9b6176' },
    9: { background: '#ED6D05', foreground: '#FFF', toned: '#fad3b4', tonedFont: '#a07a5a' },
    10: { background: '#BFCB36', foreground: '#FFF', toned: '#ecefc2', tonedFont: '#929669' },
    11: { background: '#009788', foreground: '#FFF', toned: '#b2e0db', tonedFont: '#598682' },
    12: { background: '#7885CA', foreground: '#FFF', toned: '#d6daef', tonedFont: '#7d8196' },
    13: { background: '#785548', foreground: '#FFF', toned: '#d6ccc8', tonedFont: '#7d726e' },
    14: { background: '#D40500', foreground: '#FFF', toned: '#f2b4b2', tonedFont: '#995a59' },
    15: { background: '#F09400', foreground: '#FFF', toned: '#fadfb2', tonedFont: '#a18559' },
    16: { background: '#7DB33E', foreground: '#FFF', toned: '#d8e8c5', tonedFont: '#7e8f6b' },
    17: { background: '#B39BDD', foreground: '#FFF', toned: '#e8e1f5', tonedFont: '#8f879b' },
    18: { background: '#616161', foreground: '#FFF', toned: '#cfcfcf', tonedFont: '#767676' },
    19: { background: '#EC7873', foreground: '#FFF', toned: '#f9d6d5', tonedFont: '#a07d7b' },
    20: { background: '#4582F8', foreground: '#FFF', toned: '#c7d9fd', tonedFont: '#6d80a4' },
    21: { background: '#9D69AF', foreground: '#FFF', toned: '#e1d2e7', tonedFont: '#88788d' },
    22: { background: '#A79B8E', foreground: '#FFF', toned: '#e4e1dd', tonedFont: '#8b8784' },
  },
  PRIMARY_EVENT_COLOR: '#00A7E7',
  HISTORICAL_EVENT_COLOR: '#E8E8E8',
  RESIDENT_TASK_EVENT_COLOR: '#7795CE',
  NEXUS_DISPENSED_EVENT_COLOR: '#41BE88',
  NEXUS_NOT_DISPENSED_EVENT_COLOR: '#28A297',
  NOACCESS_EVENT_COLOR: '#a6a4a4',
};
