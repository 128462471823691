import { String } from '@sekoia/shared/utils/stringExtensions';
import Rest from 'src/utils/rest/rest';
import ProfileStorageManager from 'src/utils/CurrentProfileProvider/ProfileStorageManager';
import { Authentication } from 'src/utils/authentication/Authentication';
import { ai } from '@sekoia/shared/utils/telemetryService';

interface IProfile {
  id: string;
  customerUrl?: string;
  customerId?: string;
  cultureInfoCode?: string;

  legacyId: number;
  node?: INode;
  hashCode: number;

  address: string;
  city: string;
  displayName: string;
  email: string;
  firstName: string;
  fullName: string;
  lastName: string;
  name: string;
  phone: string;
  postalCode: string;
  title: string;
  wantsChinabookNotificationEmail: boolean;
  ssn: string;

  organizationIds?: string[];
  employeeId?: string;
  pipeSeparatedEmployeeGroupIds?: string;
  professionId?: number;

  roles: string[];

  [key: string]: string | number | boolean | LegacyId | INode | string[] | undefined;
}
interface INode {
  type: NodeType;
  id: string;
  legacyId: LegacyId;

  parentId: string;

  customerId: string;
  departmentId: string;
  organizationId: string;
  apartmentId: string;

  isActive: boolean;
  isDeleted: boolean;
}

interface LegacyId {
  type: 'Citizen' | 'Location';
  value: number;
}

type NodeType = 'Customer' | 'Organization' | 'Department' | 'Apartment' | 'Citizen';

/**
 * @deprecated Get customer information from `useProfileContext` instead.
 */
interface ICurrentProfileProvider {
  setCurrentProfile(
    profileId: string,
    customerId: string,
    customerUrl: string,
    cultureInfoCode: string,
  ): Promise<boolean>;
  getCurrentProfile(): IProfile | null;
  clearCurrentProfile(): void;
}

export default class CurrentProfileProvider implements ICurrentProfileProvider {
  private _profileStorageManager = new ProfileStorageManager();
  public static Instance: ICurrentProfileProvider = new CurrentProfileProvider();

  clearCurrentProfile(): void {
    this._profileStorageManager.removeCurrentProfile();
  }

  public async setCurrentProfile(
    profileId: string,
    customerId: string,
    customerUrl: string,
    cultureInfoCode: string,
  ): Promise<boolean> {
    if (String.isNullOrWhiteSpace(profileId) || String.isNullOrWhiteSpace(customerUrl)) {
      ai.trackException(`profileId or customerUrl is null`);
      throw new Error('profileId or customerUrl is null');
    }

    const body = {
      ids: [profileId],
    };

    const profiles = await Rest.execute(
      {
        url: '/api/directory/profiles/batch-fetch',
        method: 'POST',
        body: body,
      },
      { id: profileId, customerUrl: customerUrl, customerId: customerId },
    );

    const currentProfile = profiles[0];
    currentProfile.customerUrl = customerUrl;
    currentProfile.customerId = customerId;
    currentProfile.cultureInfoCode = cultureInfoCode;

    const roles = await Authentication.Instance.getRolesOfCurrentUser();
    currentProfile.roles = roles;
    this._profileStorageManager.setCurrentProfile(JSON.stringify(currentProfile));

    return true;
  }

  public getCurrentProfile() {
    const p = this._profileStorageManager.getCurrentProfile();
    const profile: IProfile | null = p ? JSON.parse(p) : null;

    if (!profile) {
      return null;
    }
    return profile;
  }
}
