class CacheKeys {
  static readonly NOTIFICATIONS = 'NOTIFICATIONS';
  static readonly NOTIFICATION_COUNT = 'NOTIFICATION_COUNT';
  static readonly SUBSCRIPTIONS = 'SUBSCRIPTIONS';
  static readonly RESIDENTS = 'RESIDENTS';
  static readonly RESIDENT = 'RESIDENT';
  static readonly EMPLOYEES = 'EMPLOYEES';
  static readonly EMPLOYEES_METADATA = 'EMPLOYEES_METADATA';
  static readonly EMPLOYEE = 'EMPLOYEE';
  static readonly EMPLOYEE_PROFILE = 'EMPLOYEE_PROFILE';
  static readonly USER = 'USER';
  static readonly USERS = 'USERS';
  static readonly INFINITY_RECORDS = 'INFINITY_RECORDS';
  static readonly RESIDENT_FORMS = 'RESIDENT_FORMS';
  static readonly RESIDENT_ASSIGNED_AND_NOT_ASSIGNED_FORMS = 'RESIDENT_ASSIGNED_AND_NOT_ASSIGNED_FORMS';
  static readonly CATEGORIES = 'CATEGORIES';
  static readonly CATEGORY_SETS = 'CATEGORY_SETS';
  static readonly PLANS = 'PLANS';
  static readonly PLANS_FOR_RESIDENT = 'PLANS_FOR_RESIDENT';
  static readonly PLANS_FOR_RESIDENTS = 'PLANS_FOR_RESIDENTS';
  static readonly RECORDS_FORMS = 'RECORDS_FORMS';
  static readonly RECORDS_MULTIPLE_RESIDENTS = 'RECORDS_MULTIPLE_RESIDENTS';
  static readonly FORM_FOLDERS = 'FORM_FOLDERS';
  static readonly FORMS_REGISTRATIONS_FOR_RESIDENTS = 'FORMS_REGISTRATIONS_FOR_RESIDENTS';
  static readonly FORMS_BY_RESIDENT = 'FORMS_BY_RESIDENT';
  static readonly FORMSBASE = 'FORMSBASE';
  static readonly FORMS_REGISTRATIONS_BY_FORM_ID = 'FORMS_REGISTRATIONS_BY_FORM_ID';
  static readonly PLANS_ASSOCIATED_FORMS = 'PLANS_ASSOCIATED_FORMS';
  static readonly PLAN_LOG = 'PLAN_LOG';
  static readonly PLAN_RECORDS_ENTRIES = 'PLAN_RECORDS_ENTRIES';
  static readonly ACCESS_CONTROL_TAGS = 'ACCESS_CONTROL_TAGS';
  static readonly PLANS_HEADING = 'PLANS_HEADING';
  static readonly RESIDENTS_TASKS_PERIOD = 'RESIDENTS_TASKS_PERIOD';
  static readonly RESIDENTS_TASK = 'RESIDENTS_TASK';
  static readonly RESIDENTS_PLANNED_ADMINISTRATIONS = 'RESIDENTS_PLANNED_ADMINISTRATIONS';
  static readonly RESIDENTS_PLANNED_ADMINISTRATIONS_PERIOD = 'RESIDENTS_PLANNED_ADMINISTRATIONS_PERIOD';
  static readonly RESIDENTS_STATES_AND_EFFECTS_PERIOD = 'RESIDENTS_STATES_AND_EFFECTS_PERIOD';
  static readonly STANDARD_TASKS_BY_ORGANISATION = 'STANDARD_TASKS_BY_ORGANISATION';
  static readonly STANDARD_TASKS_FILTERED = 'STANDARD_TASK';
  static readonly TASK_IMAGE = 'TASK_IMAGE';
  static readonly TASKS_INSTRUCTIONS_ASSIGNMENT = 'TASKS_INSTRUCTIONS_ASSIGNMENT';
  static readonly INSTRUCTIONS = 'INSTRUCTIONS';
  static readonly DELEGATIONS_BY_VALUES = 'DELEGATIONS_BY_VALUES';
  static readonly TAGS = 'TAGS';
  static readonly EMPLOYEE_DELEGATIONS = 'EMPLOYEE_DELEGATIONS';
  static readonly EMPLOYEE_DELEGATIONS_EXPIRATIONS = 'EMPLOYEE_DELEGATIONS_EXPIRATIONS';
  static readonly STANDARD_TASKS_FORMS = 'STANDARD_TASKS_FORMS';
  static readonly NEXUS_PROBLEMS_WITH_SUBGOALS = 'NEXUS_PROBLEMS_WITH_SUBGOALS';
  static readonly NEXUS_SUBGOALS_WITH_ACTIONS = 'NEXUS_SUBGOALS_WITH_ACTIONS';
  static readonly NEXUS_SUBGOALS_ASSICIATED_FORMS = 'NEXUS_SUBGOALS_ASSICIATED_FORMS';
  static readonly NEXUS_PN_ADMINISTRATION_DETAIL = 'NEXUS_PN_ADMINISTRATION_DETAIL';
  static readonly NEXUS_PN_HISTORY_DETAILS = 'NEXUS_PN_HISTORY_DETAILS';
  static readonly NEXUS_PN_PRESCRIPTION = 'NEXUS_PN_PRESCRIPTION';
  static readonly NEXUS_PN_PRESCRIPTIONS = 'NEXUS_PN_PRESCRIPTIONS';
  static readonly NEXUS_PN_AVAILABLE_ACTIONS = 'NEXUS_PN_AVAILABLE_ACTIONS';
  static readonly NEXUS_PLANNED_ADMINISTRATION_DETAIL = 'NEXUS_PLANNED_ADMINISTRATION_DETAIL';
  static readonly NEXUS_NOT_DISPENSED_DETAIL = 'NEXUS_NOT_DISPENSED_DETAIL';
  static readonly NEXUS_RESIDENT_FORMS = 'NEXUS_RESIDENT_FORMS';
  static readonly NEXUS_RESIDENT_PERSONAL_DATA = 'NEXUS_RESIDENT_PERSONAL_DATA';
  static readonly NEXUS_RESIDENT_CONTACTS_RELATIVES = 'NEXUS_RESIDENT_CONTACTS_RELATIVES';
  static readonly NEXUS_RESIDENT_PLANS = 'NEXUS_RESIDENT_PLANS';
  static readonly NEXUS_CUSTOMER_CONFIGURATION = 'NEXUS_CUSTOMER_CONFIGURATION';
  static readonly TASKS_ASSIGNMENT_AUDIT = 'TASKS_ASSIGNMENT_AUDIT';
  static readonly STANDARDTASKS_ASSIGNED_TO_RESIDENT = 'STANDARDTASKS_ASSIGNED_TO_RESIDENT';
  static readonly RESIDENTS_TASKS_ASSIGN = 'RESIDENTS_TASKS_ASSIGN';
  static readonly TASKS_ASSOCIATED_WITH_NEXUS_SUBGOAL = 'TASKS_ASSOCIATED_WITH_NEXUS_SUBGOAL';
  static readonly FORMS_ASSOCIATED_WITH_NEXUS_SUBGOAL = 'FORMS_ASSOCIATED_WITH_NEXUS_SUBGOAL';
  static readonly FORMS_ASSOCIATED_WITH_PLANS = 'FORMS_ASSOCIATED_WITH_PLANS';
  static readonly FORMS_ANSWERS_FOR_REGISTRATION = 'FORMS_ANSWERS_FOR_REGISTRATION';
  static readonly REGISTRATION_WITH_ANSWERS = 'REGISTRATION_WITH_ANSWERS';
  static readonly REGISTRATION_WITH_FILTERS = 'REGISTRATION_WITH_FILTERS';
  static readonly REGISTRATIONS_ASSOCIATED_WITH_PLAN = 'REGISTRATIONS_ASSOCIATED_WITH_PLAN';
  static readonly REGISTRATIONS_ASSOCIATED_WITH_VUM = 'REGISTRATIONS_ASSOCIATED_WITH_VUM';
  static readonly RESIDENTS_NO_REGISTRATIONS_FOR_FORM = 'RESIDENTS_NO_REGISTRATIONS_FOR_FORM';
  static readonly TASK_INSTANCE_ASSIGNMENTS = 'TASK_INSTANCE_ASSIGNMENTS';
  static readonly FLUID_TYPES = 'FLUID_TYPES';
  static readonly FLUID_TARGET_VALUES = 'FLUID_TARGET_VALUES';
  static readonly FLUID_REGISTRATION_FOLLOWUPS = 'FLUID_REGISTRATION_FOLLOWUPS';
  static readonly FLUID_REGISTRATION_DETAILS_FOLLOWUPS = 'FLUID_REGISTRATION_DETAILS_FOLLOWUPS';
  static readonly FLUID_REGISTRATION_FOLLOWUP = 'FLUID_REGISTRATION_FOLLOWUP';
  static readonly DASHBOARDS = 'DASHBOARDS';
  static readonly WIDGETS = 'WIDGETS';
  static readonly LOCATION_HIERARCHY = 'LOCATION_HIERARCHY';
  static readonly TASKS_REPORT_METRICS = 'TASKS_REPORT_METRICS';
  static readonly TASKS_INSTANCES = 'TASKS_INSTANCES';
  static readonly TASK_INSTANCE = 'TASK_INSTANCE';
  static readonly PROFESSIONS = 'PROFESSIONS';
  static readonly CHINABOOK_CONVERSATIONS = 'CHINABOOK_CONVERSATIONS';
  static readonly FORM_WITH_STEPS_AND_CHOICES = 'FORM_WITH_STEPS_AND_CHOICES';
  static readonly PROFILE_PICTURE_URL = 'PROFILE_PICTURE_URL';
  static readonly PROFILE_FROM_SERVER = 'PROFILE_FROM_SERVER';
  static readonly TASKS_TO_SHOW_IN_LIST = 'TASKS_TO_SHOW_IN_LIST';
  static readonly TASKS_EXECUTED_BY_ID = 'TASKS_EXECUTED_BY_ID';
  static readonly REPORTS = 'REPORTS';
  static readonly INCIDENT_TEMPLATES = 'INCIDENT_TEMPLATES';
  static readonly INCIDENT_PROTOTYPE = 'INCIDENT_PROTOTYPE';
  static readonly INCIDENTS_META = 'INCIDENTS_META';
  static readonly INCIDENT_ACTIONS = 'INCIDENT_ACTIONS';
  static readonly INCIDENTS_ACTION_TYPES = 'INCIDENTS_ACTION_TYPES';
  static readonly INCIDENTS_ACTION = 'INCIDENTS_ACTION';
  static readonly INCIDENT_DETAIL = 'INCIDENT_DETAIL';
  static readonly INCIDENT_CONTENT_DETAIL = 'INCIDENT_CONTENT_DETAIL';
  static readonly INCIDENT_ANALYSIS_DETAIL = 'INCIDENT_ANALYSIS_DETAIL';
  static readonly INCIDENT_ACTION_DETAIL = 'INCIDENT_ACTION_DETAIL';
  static readonly INCIDENT_FINAL_COMMENT_DETAIL = 'INCIDENT_FINAL_COMMENT_DETAIL';
  static readonly MEDICINE_PN_EFFECT_REGISTRATION_OPTIONS = 'MEDICINE_EFFECT_REGISTRATION_OPTIONS';
  static readonly MEDICINE_INVENTORY_ITEM = 'MEDICINE_INVENTORY_ITEM';
  static readonly MEDICINE_INVENTORY_FOR_RESIDENT = 'MEDICINE_INVENTORY_FOR_RESIDENT';
  static readonly MEDICINE_PN_PRESCRIPTIONS_FOR_RESIDENT = 'MEDICINE_PN_PRESCRIPTIONS_FOR_RESIDENT';
  static readonly MEDICINE_PN_PRESCRIPTIONS = 'MEDICINE_PN_PRESCRIPTIONS';
  static readonly MEDICINE_PN_PRESCRIPTION = 'MEDICINE_PN_PRESCRIPTION';
  static readonly MEDICINE_REGISTRATIONS_PN_PRESCRIPTION = 'MEDICINE_REGISTRATIONS_PN_PRESCRIPTION';
  static readonly MEDICINE_REGISTRATIONS_NOT_DISPENSED_PRESCRIPTION =
    'MEDICINE_REGISTRATIONS_NOT_DISPENSED_PRESCRIPTION';
  static readonly MEDICINE_NOT_DISPENSED_PRESCRIPTIONS = 'MEDICINE_NOT_DISPENSED_PRESCRIPTIONS';
  static readonly MEDICINE_NOT_DISPENSED_PRESCRIPTION = 'MEDICINE_NOT_DISPENSED_PRESCRIPTION';
  static readonly MEDICINE_PRESCRIPTION_DOSAGE_UNIT_OPTIONS = 'MEDICINE_PRESCRIPTION_DOSAGE_UNIT_OPTIONS';
  static readonly MEDICINE_PRESCRIPTION_ROUTE_OF_ADMINISTRATION_OPTIONS =
    'MEDICINE_PRESCRIPTION_ROUTE_OF_ADMINISTRATION_OPTIONS';
  static readonly MEDICINE_PRESCRIPTION_AUDIT = 'MEDICINE_PRESCRIPTION_AUDIT';
  static readonly MEDICINE_INVENTORY_HISTORY = 'MEDICINE_INVENTORY_HISTORY';
  static readonly MEDICINE_TASKS = 'MEDICINE_TASKS';
  static readonly MEDICINE_PN_REGISTRATION = 'MEDICINE_PN_REGISTRATION';
  static readonly MEDICINE_NOT_DISPENSED_REGISTRATION = 'MEDICINE_NOT_DISPENSED_REGISTRATION';
  static readonly MEDICINE_PRESCRIPTION_PN_REGISTRATION_HISTORY_LOG =
    'MEDICINE_PRESCRIPTION_PN_REGISTRATION_HISTORY_LOG';
  static readonly MEDICINE_PRESCRIPTION_NOT_DISPENSED_REGISTRATION_HISTORY_LOG =
    'MEDICINE_PRESCRIPTION_NOT_DISPENSED_REGISTRATION_HISTORY_LOG';
  static readonly MEDICINE_TYPES = 'MEDICINE_TYPES';
  static readonly IMAGES_ALBUMS = 'IMAGES_ALBUMS';
  static readonly IMAGES_ALBUM_IMAGES = 'IMAGES_ALBUM_IMAGES';
  static readonly IMAGE = 'IMAGE';
  static readonly IMAGE_URL = 'IMAGE_URL';
  static readonly RESIDENT_BASIC_INFORMATION_HEALTH_AUDIT = 'RESIDENT_BASIC_INFORMATION_HEALTH_AUDIT';
  static readonly INTERNAL_CONTACT_AUDIT = 'INTERNAL_CONTACT_AUDIT';
  static readonly HEALTH_MEASUREMENT_TYPES = 'HEALTH_MEASUREMENT_TYPES';
  static readonly HEALTH_MEASUREMENT_TYPE = 'HEALTH_MEASUREMENT_TYPE';
  static readonly RESIDENT_BASIC_INFORMATION = 'RESIDENT_BASIC_INFORMATION';
  static readonly RESIDENT_HEALTH_INFORMATION = 'RESIDENT_HEALTH_INFORMATION';
  static readonly RESIDENT_ANAMNESIS = 'RESIDENT_ANAMNESIS';
  static readonly RESIDENT_CONSENT = 'RESIDENT_CONSENT';
  static readonly RESIDENT_KEY_DATA = 'RESIDENT_KEY_DATA';
  static readonly HEALTH_MEASUREMENT_RESPIRATORY_RATES = 'HEALTH_MEASUREMENT_RESPIRATORY_RATES';
  static readonly HEALTH_MEASUREMENT_HEIGHT = 'HEALTH_MEASUREMENT_HEIGHT';
  static readonly HEALTH_MEASUREMENT_HEART_RATES = 'HEALTH_MEASUREMENT_HEART_RATES';
  static readonly HEALTH_MEASUREMENT_BLOODPRESSURES = 'HEALTH_MEASUREMENT_BLOODPRESSURES';
  static readonly HEALTH_MEASUREMENT_OXYGEN_SATURATIONS = 'HEALTH_MEASUREMENT_OXYGEN_SATURATIONS';
  static readonly HEALTH_MEASUREMENT_BLOOD_GLUCOSES = 'HEALTH_MEASUREMENT_BLOOD_GLUCOSES';
  static readonly HEALTH_MEASUREMENT_BODY_WEIGHT = 'HEALTH_MEASUREMENT_BODY_WEIGHT';
  static readonly HEALTH_MEASUREMENT_BODY_TEMPERATURE = 'HEALTH_MEASUREMENT_BODY_TEMPERATURE';
  static readonly RESIDENTS_CALENDAR_EVENTS = 'RESIDENTS_CALENDAR_EVENTS';
  static readonly RESIDENT_ACTIVITY_GROUPS = 'RESIDENT_ACTIVITY_GROUPS';
  static readonly ACTIVITY_INSTANCE = 'ACTIVITY_INSTANCE';
  static readonly RESIDENTS_ACTIVITY_GROUP_EVENTS = 'RESIDENTS_ACTIVITY_GROUP_EVENTS';
  static readonly RESIDENT_DATA_ACCESSCONTROL_TAGS = 'RESIDENT_DATA_ACCESS_CONTROL_TAGS';
  static readonly PROFESSION_CONFIGURATION = 'PROFESSION_CONFIGURATION';
  static readonly ACTIVITY_GROUPS = 'ACTIVITY_GROUPS';
  static readonly ACTIVITIES_MEMBERS = 'ACTIVITIES_MEMBERS';
  static readonly ACTIVITY_GROUP_CALENDAR_EVENTS = 'ACTIVITY_GROUP_CALENDAR_EVENTS';
  static readonly ACTIVITY_GROUP_CALENDAR_EVENT = 'ACTIVITY_GROUP_CALENDAR_EVENT';
  static readonly ACTIVITY_GROUP_CALENDAR_EVENT_INSTANCE = 'ACTIVITY_GROUP_CALENDAR_EVENT_INSTANCE';
  static readonly ACTIVITY_GROUP_CALENDAR_STANDARD_EVENTS = 'ACTIVITY_GROUP_CALENDAR_STANDARD_EVENTS';
  static readonly ACTIVITY_GROUP_MEMBERS = 'ACTIVITY_GROUP_MEMBERS';
  static readonly ACTIVITY_CALENDAR_GROUPS = 'ACTIVITY_CALENDAR_GROUPS';
  static readonly ACTIVITY_CALENDAR_STANDARD_ACTIVITIES = 'ACTIVITY_CALENDAR_STANDARD_ACTIVITIES';
  static readonly RESIDENTS_ACTIVITY_GROUPS_EVENTS = 'RESIDENTS_ACTIVITY_GROUPS_EVENTS';
  static readonly CUSTOMER_TOGGLES = 'CUSTOMER_TOGGLES';
  static readonly INVENTORY_HIERARCHY = 'INVENTORY_HIERARCHY';
  static readonly INVENTORY_ITEMS = 'INVENTORY_ITEMS';
  static readonly MANAGEMENT_INFORMATION = 'MANAGEMENT_INFORMATION';
  static readonly RESIDENT_INSTRUCTIONS = 'RESIDENT_INSTRUCTIONS';
  static readonly RESIDENT_INSTRUCTION_ENTRIES = 'RESIDENT_INSTRUCTION_ENTRIES';
}

export default CacheKeys;
