import React, { PureComponent } from 'react';
import Clear from '@mui/icons-material/Clear';
import Backspace from '@mui/icons-material/Backspace';
import { Grid, Divider } from '@mui/material';

export default class PunNumpad extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.addEventListener('keydown', this.keyboardPressed, true);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyboardPressed, true);
  }

  keyboardPressed = (event) => {
    const { clickHandler, deleteEntry } = this.props;
    switch (event.keyCode) {
      case 48:
        clickHandler(0);
        break;

      case 49:
        clickHandler(1);
        break;

      case 50:
        clickHandler(2);
        break;

      case 51:
        clickHandler(3);
        break;

      case 52:
        clickHandler(4);
        break;

      case 53:
        clickHandler(5);
        break;

      case 54:
        clickHandler(6);
        break;

      case 55:
        clickHandler(7);
        break;

      case 56:
        clickHandler(8);
        break;

      case 57:
        clickHandler(9);
        break;

      case 8:
        deleteEntry();
        break;

      case 96:
        clickHandler(0);
        break;

      case 97:
        clickHandler(1);
        break;

      case 98:
        clickHandler(2);
        break;

      case 99:
        clickHandler(3);
        break;

      case 100:
        clickHandler(4);
        break;

      case 101:
        clickHandler(5);
        break;

      case 102:
        clickHandler(6);
        break;

      case 103:
        clickHandler(7);
        break;

      case 104:
        clickHandler(8);
        break;

      case 105:
        clickHandler(9);
        break;
      default:
        break;
    }
  };

  numPadBtn(numPadBtnValue) {
    const { appearance, clickHandler } = this.props;
    return (
      <div className={appearance.btnNumpad} onClick={() => clickHandler(numPadBtnValue)}>
        {numPadBtnValue}
      </div>
    );
  }

  render() {
    const { appearance, deleteEntry } = this.props;

    return (
      <div className={appearance.numpad}>
        {window.innerHeight > window.innerWidth && <Divider style={{ marginBottom: 32 }} />}
        <Grid container>
          <Grid item xs={4}>
            {this.numPadBtn(1)}
          </Grid>
          <Grid item xs={4}>
            {this.numPadBtn(2)}
          </Grid>
          <Grid item xs={4}>
            {this.numPadBtn(3)}
          </Grid>
          <Grid item xs={4}>
            {this.numPadBtn(4)}
          </Grid>
          <Grid item xs={4}>
            {this.numPadBtn(5)}
          </Grid>
          <Grid item xs={4}>
            {this.numPadBtn(6)}
          </Grid>
          <Grid item xs={4}>
            {this.numPadBtn(7)}
          </Grid>
          <Grid item xs={4}>
            {this.numPadBtn(8)}
          </Grid>
          <Grid item xs={4}>
            {this.numPadBtn(9)}
          </Grid>
          <Grid item xs={4}>
            <div className={appearance.btnNumpad} onClick={() => deleteEntry()}>
              <Backspace />
            </div>
          </Grid>
          <Grid item xs={4}>
            {this.numPadBtn(0)}
          </Grid>
          <Grid item xs={4}>
            <div className={appearance.btnNumpad} onClick={() => deleteEntry('all')}>
              <Clear />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}
