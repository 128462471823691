import * as types from 'src/redux/constants';
import { createSelector } from 'reselect';

// ======================================================
// Simple persistent cache
// ======================================================

const getCacheFromPath = (path) => (state) => state.cache.getIn(path);

// Save cache on path
export const save = (path, data, groupBy) => {
  if (!Array.isArray(path)) path = [path];
  return {
    type: types.CACHE_SAVE,
    payload: {
      path,
      data,
      groupBy,
    },
  };
};

// Wipe cache on path.
export const clear = (path) => {
  if (!Array.isArray(path)) path = [path];
  return {
    type: types.CACHE_CLEAR,
    payload: {
      path,
    },
  };
};

// Get a selector to a path in the cache
export const generateSelector = (path) => createSelector([getCacheFromPath(path)], (cache) => cache);

// Get a value from a path
export const get = (path) => (d, getState) => {
  if (!Array.isArray(path)) path = [path];
  return getState().cache.getIn(path);
};
