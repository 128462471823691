import { DeviceInfoManager } from '@sekoia/shared/utils/DeviceInfoManager';
import { ai } from '@sekoia/shared/utils/telemetryService';
import base64url from 'base64url';
import { Authentication } from 'src/utils/authentication/Authentication';
import { getPublicKey, getSignedMessage } from 'src/utils/nativeCommunication';
import { v4 as uuidv4 } from 'uuid';

export class ResidentAuthentication {
  public static Instance = new ResidentAuthentication();

  private _signature: string | undefined;
  private _salt: string | undefined;

  public async initiateResidentLogin() {
    this._salt = uuidv4();
    const deviceId = DeviceInfoManager.Instance.getDeviceId();
    const residentId = DeviceInfoManager.Instance.getResidentId();

    try {
      this._signature = await getSignedMessage(`${deviceId}${residentId}${this._salt}`);
    } catch (error) {
      ai.trackException(error);
    }

    try {
      await this.navigateToResidentLogin();
    } catch (error) {
      ai.trackException(error);
      Authentication.Instance.logout();
    }
  }

  private async navigateToResidentLogin() {
    const deviceId = DeviceInfoManager.Instance.getDeviceId();
    const customerId = DeviceInfoManager.Instance.getCustomerId();
    const residentId = DeviceInfoManager.Instance.getResidentId();

    if (!deviceId || !customerId || !residentId) {
      throw Error(`'deviceId', 'customerId' or 'residentId' not present on device!`);
    }

    const signature = this._signature;
    if (!signature) {
      throw Error(`'signature' could not be found!`);
    }

    const publicKey = await getPublicKey();
    if (!publicKey) {
      throw Error(`'publicKey' not received from device!`);
    }

    const salt = this._salt;
    if (!salt) {
      throw Error(`No salt generated!`);
    }

    ai.trackTrace(`Logging in resident, DeviceId: ${deviceId}, customerId: ${customerId}, residentId: ${residentId}`);

    await Authentication.Instance.login(
      new ResidentArguments(signature, publicKey, salt, deviceId, customerId, residentId).acrValues(),
    );
  }
}

class ResidentArguments {
  signature: string;
  publicKey: string;
  salt: string;
  deviceId: string;
  customerId: string;
  residentId: string;

  constructor(
    signature: string,
    publicKey: string,
    salt: string,
    deviceId: string,
    customerId: string,
    residentId: string,
  ) {
    this.signature = signature;
    this.publicKey = publicKey;
    this.salt = salt;
    this.deviceId = deviceId;
    this.customerId = customerId;
    this.residentId = residentId;
  }

  acrValues(): string {
    return [
      `customerId:${this.customerId}`,
      `residentId:${this.residentId}`,
      `deviceId:${this.deviceId}`,
      `salt:${this.salt}`,
      `signature:${this.signature}`,
      `publicKey:${base64url(this.publicKey)}`,
    ].join(' ');
  }
}
