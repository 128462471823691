import { useContext } from 'react';
import { RestClientsContext } from '@sekoia/shared/contexts/RestClients/RestClientsContext';
import { ai } from '@sekoia/shared/utils/telemetryService';

export const useAtlasClient = () => {
  const { atlas } = useContext(RestClientsContext);

  if (!atlas) {
    ai.trackException(`No Atlas client found, did you forget to wrap the application with a <RestClientsProvider />?`);
    throw new Error(`No Atlas client found, did you forget to wrap the application with '<RestClientsProvider />?`);
  }

  return atlas;
};
