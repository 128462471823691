import React, { memo } from 'react';

type TypographyProps = {
  variant?: 'title' | 'subtitle';
  type?: string;
  color?: string;
  ellipsis?: boolean;
  style?: unknown;
  children: React.ReactNode;
};

const Typography = ({ variant, type, color, ellipsis, style, children, ...rest }: TypographyProps) => {
  const styles = {
    title: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: '1rem',
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      fontWeight: 400,
      letterSpacing: '0.00938em',
    },
    subtitle: {
      color: 'rgba(0, 0, 0, 0.70)',
      fontSize: '0.875rem',
      lineHeight: '1.2rem',
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      fontWeight: 400,
      letterSpacing: '0.01071em',
    },
  };

  let variantStyles = variant ? styles[variant] : styles.title;
  if (color) variantStyles.color = color;
  if (ellipsis)
    variantStyles = Object.assign(
      { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
      variantStyles,
    );

  if (style) variantStyles = Object.assign(style, variantStyles);

  const elm = React.createElement(`${type ? type : 'span'}`, { style: variantStyles, ...rest }, children);
  return elm;
};

export default memo(Typography);
