import * as directoryRequests from 'src/requests/directoryRequests';
import { Authentication } from 'src/utils/authentication/Authentication';
import requestRun from 'src/utils/request/requestRun';

class PinManager {
  async extendProfile() {
    const currentProfile = await Authentication.Instance.getProfileInformationFromToken();
    const globalId = currentProfile?.globalId;
    if (globalId !== null) {
      const profilePicDimension = 64;

      await requestRun(directoryRequests.profilePictureUrl(globalId, profilePicDimension, profilePicDimension)).then(
        (res) => {
          localStorage.setItem('profilePicUrl', res.pictureUrl);
        },
      );
    }
  }
}

export default PinManager;
