import * as types from 'src/redux/constants';

export const add = (message, onRetry, forceRetry) => ({
  type: types.ERROR_ADD,
  payload: {
    message,
    onRetry,
    forceRetry,
  },
});

export const clear = () => ({
  type: types.ERROR_CLEAR,
  payload: {},
});
