import React, { ReactNode, useEffect, useRef } from 'react';
import { Authentication } from 'src/utils/authentication/Authentication';
import JWTTokenHelper from './jwtTokenHelper';
import { ai } from '@sekoia/shared/utils/telemetryService';
import { hidePin as setHidePin } from 'src/redux/appSettings/appSettingsActions';
import { useDispatch } from 'react-redux';
import { ResidentAuthentication } from 'src/utils/authentication/ResidentAuthentication';
import UserManagerSettingsResident from 'src/utils/authentication/userManagerSettings/UserManagerSettingsResident';

const tokenExpirationThresholdInSeconds = 180;
const silentRefreshInterval = 1000 * 10;
const tokenHelper = new JWTTokenHelper();

type Props = {
  children?: ReactNode;
};

export const SilentRefresh = ({ children }: Props) => {
  const silentRefreshTimer = useRef<number>();
  const silentRefreshing = useRef(false);
  const dispatch = useDispatch();

  useEffect(() => {
    silentRefreshTimer.current = window.setInterval(async () => {
      const token = await Authentication.Instance.getToken();

      if (token) {
        if (tokenHelper.isTokenExpired(token, tokenExpirationThresholdInSeconds)) {
          if (!silentRefreshing.current) {
            try {
              silentRefreshing.current = true;
              await Authentication.Instance.signinSilent();
            } catch (error) {
              const isSessionActive = Authentication.Instance.isSessionActive();
              if (isSessionActive) {
                ai.trackTrace(`SilentRefresh.tsx, timer: Showing PIN view, silent refresh failed, but valid session.`);
                dispatch(setHidePin(false));
              } else {
                ai.trackTrace(`SilentRefresh.tsx, timer: Logging out user, as session is invalid.`);
                const isResidentDevice =
                  Authentication.Instance.userManager.settings.client_id === UserManagerSettingsResident().client_id;
                if (isResidentDevice) {
                  ResidentAuthentication.Instance.initiateResidentLogin();
                } else {
                  Authentication.Instance.logout();
                }
              }
              ai.trackException(error, `Error during silent refresh`, {
                sessionExpiresAt: localStorage.getItem('sessionExpiresAt'),
              });
            } finally {
              silentRefreshing.current = false;
            }
          }
        }
      }
    }, silentRefreshInterval);
    return () => window.clearInterval(silentRefreshTimer.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};
