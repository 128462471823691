import React, { memo, useRef, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { withRouter } from 'react-router-dom';
import { swipeAnimationZIndex } from 'src/utils/zIndexConstants';

const styles = () => ({
  swipeAnimation: {
    position: 'fixed',
    left: 0,
    bottom: -50,
    top: -50,
    width: 0,
    zIndex: swipeAnimationZIndex,
    borderRadius: '50%',
    backgroundColor: '#29434e',
  },
});

const SwipeBack = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const swipeAnimation = useRef();
  let xDown = null;
  let yDown = null;
  let swipeDistance = 0;

  useEffect(() => {
    swipeAnimation.current.parentElement.addEventListener('touchstart', handleTouchStart);
    swipeAnimation.current.parentElement.addEventListener('touchmove', handleTouchMove);
    swipeAnimation.current.parentElement.addEventListener('touchend', handleTouchEnd);
    swipeAnimation.current.parentElement.addEventListener('touchcancel', handleTouchCancel);
    return () => {
      if (swipeAnimation.current) {
        swipeAnimation.current.parentElement.removeEventListener('touchstart', handleTouchStart);
        swipeAnimation.current.parentElement.removeEventListener('touchmove', handleTouchMove);
        swipeAnimation.current.parentElement.removeEventListener('touchend', handleTouchEnd);
        swipeAnimation.current.parentElement.removeEventListener('touchcancel', handleTouchCancel);
      }
    };
  }, []);

  const handleClose = () => {
    props.history.goBack();
  };

  const handleTouchStart = (evt) => {
    xDown = evt.touches[0].clientX;
    yDown = evt.touches[0].clientY;
  };

  const handleTouchMove = (evt) => {
    if (!xDown || !yDown) {
      return;
    }

    const node = swipeAnimation.current;

    var xUp = evt.touches[0].clientX;
    var yUp = evt.touches[0].clientY;

    var xDiff = xDown - xUp;
    var yDiff = yDown - yUp;
    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      const value = parseInt(xDiff);
      if (value < 1) {
        if (-value <= 150) {
          node.style.width = `${-value}px`;
          node.style.left = `${value / 2}px`;
          node.style.opacity = -value / 2 / 200;
        }
        swipeDistance = -value;
      }
    }
  };

  const handleTouchEnd = () => {
    const { handler } = props;
    const node = swipeAnimation.current;
    xDown = null;
    yDown = null;
    node.style.width = 0;
    node.style.left = 0;
    if (swipeDistance >= 150) {
      swipeDistance = 0;
      handler ? handler() : handleClose();
    }
  };

  const handleTouchCancel = () => {
    const node = swipeAnimation.current;
    xDown = null;
    yDown = null;
    node.style.width = 0;
    node.style.left = 0;
  };

  return <div ref={swipeAnimation} className={classes.swipeAnimation}></div>;
};

export default withRouter(memo(SwipeBack));
