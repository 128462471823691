export interface Role {
  name: string;
  id: number;
}

export class RoleRequirement {
  requiresRole: boolean;
  allowedRoles: Role[];

  constructor(requiresRole: boolean, allowedRoles: Role[]) {
    this.requiresRole = requiresRole;
    this.allowedRoles = allowedRoles;
  }
  public static none(): RoleRequirement {
    return new RoleRequirement(false, []);
  }

  public static create(allowedRoles: Role[]): RoleRequirement {
    return new RoleRequirement(true, [...allowedRoles, roles.sekoiaAdmin]);
  }
}

export const roles = {
  sekoiaAdmin: { name: 'Admin', id: 1 },
  systemAdministrator: { name: 'Systemadministrator', id: 11 },
  administrator: { name: 'Administrator', id: 10 },
  manager: { name: 'Leder', id: 9 },
  superUser: { name: 'Superbruger', id: 12 },
  employee: { name: 'Medarbejder', id: 13 },
  activityGroupMember: { name: 'ActivityGroupMember', id: 19 },
  relative: { name: 'Relative', id: 20 },
  identityServerAdministrators: { name: 'IdentityServerAdministrators', id: 17 },
  uiComposition: { name: 'UI-Composition', id: 21 },
  citizenUser: { name: 'Citizen', id: 22 },
  employeeUser: { name: 'Employee', id: 23 },
  medicineAdministrator: { name: 'MedicineAdministrator', id: 24 },
  karunaAndInitiumClient: { name: 'KarunaAndInitiumClient', id: 25 },
};
