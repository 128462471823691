import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Authentication } from 'src/utils/authentication/Authentication';
import { Button, CircularProgress } from '@mui/material';

function LocationBasedDeviceNoAccessForResident() {
  const { t } = useTranslation(['global']);
  const [isLoading, setIsLoading] = useState(false);

  const logoutHandler = () => {
    setIsLoading(true);
    Authentication.Instance.logout();
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        bottom: 0,
        zIndex: 2,
        backgroundColor: 'white',
        left: 0,
        right: 0,
      }}
    >
      <div style={{ textAlign: 'center' }}>
        {isLoading ? (
          <CircularProgress size={50} />
        ) : (
          <>
            {t('global:errorMessages.noAccessToLocationSpecificResident')}
            <br></br>
            <br></br>
            <Button variant="contained" color="primary" onClick={logoutHandler}>
              {t('global:navigation.logout')}
            </Button>
          </>
        )}
      </div>
    </div>
  );
}
export default LocationBasedDeviceNoAccessForResident;
