export class FetchApiException extends Error {
  message: string;
  status: number;
  response: string;
  headers: Record<string, unknown>;
  result: never;

  constructor(message: string, status: number, response: string, headers: Record<string, unknown>, result: never) {
    super();

    this.message = message;
    this.status = status;
    this.response = response;
    this.headers = headers;
    this.result = result;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static isCalendarApiException(obj: any): obj is FetchApiException {
    return obj.isApiException === true;
  }
}
