import { UserManagerSettings } from 'oidc-client';

// Authentication requires a valid USerManagerSettings to be able to sign out, but it never uses the OIDC to renew tokens or anything like that.

const UserManagerSettingsKaruna = (): UserManagerSettings => {
  return {
    authority: 'dummy/issue/oauth2/',
    response_type: 'token',
    scope: 'dummy',
    client_id: 'dummy',
  };
};

export default UserManagerSettingsKaruna;
