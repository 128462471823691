import React from 'react';
import { useFetchProfilePicture } from '@sekoia/shared/hooks/requests/compass/useFetchProfilePicture';
import { useFetchUser } from '@sekoia/shared/requests/atlas/useFetchUser';
import ProfilePictureAvatarRender from './ProfilePictureAvatarRender';
import { Skeleton } from '@mui/material';

type ProfilePictureAvatarProps = {
  customerId: string;
  globalId?: string;
  size?: number;
  style?: React.CSSProperties;
  userNames?: {
    firstName: string;
    lastName: string;
  };
};

const ProfilePictureAvatar: React.FC<React.PropsWithChildren<ProfilePictureAvatarProps>> = (props) => {
  const { customerId, globalId, size, style, userNames } = props;
  const profilePictureDimension = size ? size : 40;

  const { data: pictureUrl, fetchStatus: pictureFetchStatus } = useFetchProfilePicture(
    customerId,
    globalId,
    profilePictureDimension,
  );
  const { data: user, fetchStatus: userFetchStatus } = useFetchUser(globalId, userNames === undefined);

  if (pictureFetchStatus === 'fetching' || userFetchStatus === 'fetching') {
    return (
      <Skeleton variant="circular" width={profilePictureDimension} height={profilePictureDimension} style={style} />
    );
  }

  return (
    <ProfilePictureAvatarRender
      name={user ? `${user.firstName} ${user.lastName}` : undefined}
      pictureUrl={pictureUrl}
      style={style}
      size={profilePictureDimension}
    />
  );
};

export default React.memo(ProfilePictureAvatar);
