import { DefaultError, QueryKey, UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';
import { ErrorType } from '@sekoia/shared/requests/atlas/useFetchUser';
import { ai } from '@sekoia/shared/utils/telemetryService';

export function useQueryWithRetry<
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(options: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>): UseQueryResult<TData, TError> {
  return useQuery({
    retry: (failureCount: number, error) => {
      const status = (error as ErrorType).status;
      let statusIn400Range = false;
      if (status >= 400 && status < 500) statusIn400Range = true;
      return failureCount < 3 && !statusIn400Range;
    },
    throwOnError: (error: TError) => {
      if ((error as Error).name === 'AbortError') {
        ai.trackException({
          exception: new Error(
            `${options.queryKey} failed due to timeout from AbortError: ${(error as Error).message}`,
          ),
        });
      } else {
        ai.trackException({
          exception: error,
        });
      }
      return false;
    },
    ...options,
  });
}
