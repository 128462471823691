import { v4 as uuidv4 } from 'uuid';
import { ai } from '@sekoia/shared/utils/telemetryService';


class OptionsTransformer {
  private static instance: OptionsTransformer;

  private constructor() {}

  public static getInstance(): OptionsTransformer {
    if (!OptionsTransformer.instance){
      OptionsTransformer.instance = new OptionsTransformer();
    }

    return OptionsTransformer.instance;
  }

  public transformFunc  =  (options: RequestInit) : Promise<RequestInit> => {
    return Promise.resolve(options);
  };

  public addDefaultHeaders(options: RequestInit) : Promise<RequestInit>{
    let headers = new Headers(options.headers);

    headers.set('sekoia.session_id', ai.getSessionId());
    headers.set('sekoia.correlation_id', uuidv4());

    options.headers = headers;

    return this.transformFunc(options); 
  }
}


export const optionsTransformer = OptionsTransformer.getInstance();
