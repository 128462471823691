import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { messageDialogZIndex } from 'src/utils/zIndexConstants';

type DialogProps = {
  open: boolean;
  title: string;
  message: string;
  onClose: () => void;
  buttonText?: string;
};

export const MessageDialog = ({ open, title, message, onClose, buttonText }: DialogProps) => {
  const { t } = useTranslation(['global']);
  return (
    <Dialog
      style={{ zIndex: messageDialogZIndex }}
      open={open}
      onClose={onClose}
      aria-labelledby="message-dialog-title"
      aria-describedby="message-dialog-description"
    >
      <DialogTitle id="message-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="message-dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          {buttonText ?? t('actions.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
