export class DeviceInfoManager {
  public static Instance = new DeviceInfoManager();
  private deviceIdKey = 'device_id';
  private customerIdKey = 'device_customer_id';
  private residentIdKey = 'device_resident_id';

  public getDeviceId() {
    return localStorage.getItem(this.deviceIdKey);
  }

  public setDeviceId(value: string): void {
    localStorage.setItem(this.deviceIdKey, value);
  }

  public getCustomerId() {
    return localStorage.getItem(this.customerIdKey);
  }

  public setCustomerId(value: string): void {
    localStorage.setItem(this.customerIdKey, value);
  }

  public getResidentId() {
    return localStorage.getItem(this.residentIdKey);
  }

  public setResidentId(value: string): void {
    localStorage.setItem(this.residentIdKey, value);
  }
}
