import { useAtlasClient } from '@sekoia/shared/hooks/common/useAtlasClient';
import CacheKeys from '@sekoia/shared/constants/CacheKeys';
import { QueryError } from '@sekoia/shared/hooks/requests/plans/useFetchPlansHeading';
import { ai } from '@sekoia/shared/utils/telemetryService';
import { useCallback } from 'react';
import { EmployeeResponse } from './useFetchEmployees';
import { useQueryClient } from '@tanstack/react-query';
import { useQueryWithRetry } from '@sekoia/shared/hooks/helpers/useQueryWithRetry';

export const useFetchEmployeeById = (employeeProfileId?: string, includeDeleted = false) => {
  const { employeeClient: client } = useAtlasClient();
  const queryClient = useQueryClient();

  const getEmployee = useCallback(
    async (employeeProfileId: string | undefined, includeDeleted: boolean) => {
      if (!employeeProfileId) return null;
      const response = await client.getEmployeeById(employeeProfileId, includeDeleted);
      return response;
    },
    [client],
  );

  return useQueryWithRetry({
    queryKey: [CacheKeys.EMPLOYEE, employeeProfileId, includeDeleted],
    queryFn: () => getEmployee(employeeProfileId, includeDeleted),
    gcTime: 5 * 60 * 1000,
    initialData: () => {
      const allEmployeesData = queryClient.getQueryData<EmployeeResponse[]>([CacheKeys.EMPLOYEES]);

      if (!allEmployeesData) {
        return undefined;
      }

      const employee = allEmployeesData.find((emp: EmployeeResponse) => emp.globalId === employeeProfileId);
      return employee;
    },
    enabled: employeeProfileId && employeeProfileId !== '' ? true : false,
    throwOnError: (e) => {
      const error = e as QueryError;
      if (error && error.status && error.status === 404) {
        // 404 indicating employee is not found.
        // We cache an undefined to avoid unecessary calls which results in 404.
        queryClient.setQueryData([CacheKeys.EMPLOYEE, employeeProfileId], undefined);
      } else {
        ai.trackException({
          exception: e,
        });
      }
      return false;
    },
  });
};
