import React from 'react';
import { Box, Button, Container, Typography, useTheme, useMediaQuery } from '@mui/material';
import { Page } from './Page';

type ErrorPageProps = {
  title: string;
  description: string;
  shouldReload: boolean;
  buttonText: string;
};

function shouldReloadWindow(shouldReload: boolean) {
  if (shouldReload) {
    window.location.reload();
  }
}

function ErrorPage(props: ErrorPageProps) {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Page title="Error page" style={{ display: 'flex', alignItems: 'center' }}>
      <Container maxWidth="lg">
        <Typography align="center" variant={mobileDevice ? 'h4' : 'h1'} color="textPrimary">
          {props.title}
        </Typography>
        <Typography align="center" variant="subtitle2" color="textSecondary">
          {props.description}
        </Typography>
        <Box mt={6} display="flex" justifyContent="center">
          <Button color="primary" variant="outlined" onClick={() => shouldReloadWindow(props.shouldReload)}>
            {props.buttonText}
          </Button>
        </Box>
      </Container>
    </Page>
  );
}

export default ErrorPage;
