import Rest from 'src/utils/rest/rest';
import currentProfileProvider from 'src/utils/CurrentProfileProvider/CurrentProfileProvider';

const requestRun = (request, onError, requestUrl = undefined) => {
  return Rest.execute(
    {
      ...request,
      onError: (response, showErrorUI) => {
        if (typeof onError === 'function') {
          return onError(response, showErrorUI);
        }
      },
    },
    currentProfileProvider.Instance.getCurrentProfile(),
    requestUrl,
  );
};

export default requestRun;
