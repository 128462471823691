import { fetch } from 'whatwg-fetch';
import { ai } from '@sekoia/shared/utils/telemetryService';
import timeout from 'src/utils/request/timeout';
import retry from 'async-retry';
import toggleServiceUrl from 'src/utils/rest/togglesServiceUrl';

const timeOutInMs = 3000;

export const canFetchDns = async () => {
  let response;
  try {
    response = await retry(
      async () =>
        await timeout(
          timeOutInMs,
          fetch(`${toggleServiceUrl()}/api/ping`, {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          }),
        ),
      {
        retries: 2,
        minTimeout: 250,
        randomize: false,
      },
    );
  } catch (e) {
    // If there is something wrong with our service, e.g services-toggle is DNS blocked which is the error but we display noInternet to the user.
    // This is misleading, but we should be able to find out very quickly that there is an error because every call to toggle service would fail.
    ai.trackException(new Error('No Internet! Could not ping services-toggle'));
    return false;
  }

  return !!response;
};
