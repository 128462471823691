import 'src/utils/polyfills';
// eslint-disable-next-line no-use-before-define
import React from 'react';

import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { HashRouter, Route } from 'react-router-dom';
import HistoryProvider from 'src/react/_hocs/history/HistoryProvider';
import store from 'src/redux/store';
import TranslatedBaseErrorView from './react/ErrorPage/TranslatedBaseErrorView';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import WebEntrance from './react/WebEntrance';
import NativeEntrance from './react/NativeEntrance';
import KarunaEntrance from './react/KarunaEntrance';
import LocationBasedDeviceEntrance from './react/LocationBasedDeviceEntrance';
import ResidentEntrance from './react/ResidentEntrance';
import { SnackbarProvider } from 'notistack';
import TranslatedSnackbarButton from './GlobalActionsCloseText';
import makeStyles from '@mui/styles/makeStyles';
import { ThemeProvider } from '@mui/material/styles';
import 'src/i18n';
import { createTheme } from './theme';
import { StyledEngineProvider } from '@mui/material';
import { CssBaseline, Button } from '@mui/material';
import { ApplicationInsightTrackKeys } from 'src/constants/ApplicationInsightTrackKeys';
import { ai } from '@sekoia/shared/utils/telemetryService';

// Clarity snippet
(function (c, l, a, r, i, t, y) {
  c[a] =
    c[a] ||
    function () {
      (c[a].q = c[a].q || []).push(arguments);
    };
  t = l.createElement(r);
  t.async = 1;
  t.src = 'https://www.clarity.ms/tag/' + i;
  y = l.getElementsByTagName(r)[0];
  y.parentNode.insertBefore(t, y);
})(window, document, 'clarity', 'script', 'j8t5u8uvw4');
// End Clarity snippet

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      cacheTime: 1000,
    },
  },
});

// Set window.getState() to return the store. This is usefull for debugging in the console.
if (process.env.NODE_ENV !== 'production') {
  window.getState = store.getState;
}

const nativeEntranceLower = '/nativeentrance';
const nativeEntrancePath = '/nativeEntrance';
const karunaEntranceLower = '/karunaentrance';
const karunaEntrancePath = '/karunaEntrance';
const locationBasedDeviceEntranceLower = '/locationbaseddeviceentrance';
const locationBasedDeviceEntrancePath = '/locationBasedDeviceEntrance';
const residentEntranceLower = '/residentdeviceentrance';
const residentEntrancePath = '/residentDeviceEntrance';

const getBaseComponent = () => {
  if (window.location.href.toLocaleLowerCase().includes(karunaEntranceLower)) {
    return KarunaEntrance;
  } else if (window.location.href.toLocaleLowerCase().includes(nativeEntranceLower)) {
    return NativeEntrance;
  } else if (window.location.href.toLocaleLowerCase().includes(locationBasedDeviceEntranceLower)) {
    return LocationBasedDeviceEntrance;
  } else if (window.location.href.toLocaleLowerCase().includes(residentEntranceLower)) {
    return ResidentEntrance;
  }
  return WebEntrance;
};

const getBasename = () => {
  if (window.location.href.toLocaleLowerCase().includes(karunaEntranceLower)) {
    return karunaEntrancePath;
  } else if (window.location.href.toLocaleLowerCase().includes(nativeEntranceLower)) {
    return nativeEntrancePath;
  } else if (window.location.href.toLocaleLowerCase().includes(locationBasedDeviceEntranceLower)) {
    return locationBasedDeviceEntrancePath;
  } else if (window.location.href.toLocaleLowerCase().includes(residentEntranceLower)) {
    return residentEntrancePath;
  }
  return '';
};

const notistackRef = React.createRef();

const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};

const theme = createTheme();

ai.startTrackPage(ApplicationInsightTrackKeys.PERFORMANCE_INDEX_TO_TASKLIST);
ai.startTrackPage(ApplicationInsightTrackKeys.PERFORMANCE_INDEX_TO_BOTTOM_BAR);
const container = document.getElementById('content');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  /**
   * offers this url for karuna: /#/karunaEntrance?access_token=
   *
   * karuna must differ between environments:
   * https://app-dev.sekoia.one
   * https://app-preprod.sekoia.one
   * https://app.sekoia.one
   */
  <Provider store={store}>
    <HashRouter basename={getBasename()}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <QueryClientProvider client={queryClient}>
            <SnackbarAndHistoryProvider />
          </QueryClientProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </HashRouter>
  </Provider>,
);

function SnackbarAndHistoryProvider() {
  const useStyles = makeStyles({
    snack: {
      position: 'absolute',
      maxWidth: 300,
      [theme.breakpoints.down('sm')]: {
        width: '65%',
      },
      '&.SnackbarContainer-bottom': {
        bottom: 66,
      },
    },
  });
  const classes = useStyles();

  return (
    <SnackbarProvider
      ref={notistackRef}
      autoHideDuration={4000}
      classes={{
        containerRoot: classes.snack,
      }}
      action={(key) => (
        <Button onClick={onClickDismiss(key)} style={{ color: theme.palette.primary.contrastText }}>
          <TranslatedSnackbarButton />
        </Button>
      )}
    >
      <HistoryProvider>
        <TranslatedBaseErrorView>
          <Route path="/" component={getBaseComponent()} />
        </TranslatedBaseErrorView>
      </HistoryProvider>
    </SnackbarProvider>
  );
}
