function timeout<T>(ms: number, promise: Promise<T>): Promise<T> {
  return new Promise(function (resolve, reject) {
    const timeoutDisposable = setTimeout(function () {
      reject(new Error('timeout'));
    }, ms);
    promise.then((v) => {
      clearTimeout(timeoutDisposable);
      resolve(v);
    }, reject);
  });
}

export default timeout;
