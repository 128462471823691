/* eslint-disable no-throw-literal */
const togglesServiceUrl = () => {
  const url = process.env.TOGGLES_SERVICE_URL;
  if (!url) {
    throw 'Unknown environment.';
  }
  return url;
};

export default togglesServiceUrl;
