import { fromJS } from 'immutable';
import * as types from 'src/redux/constants';

const initialState = fromJS({
  loginFromKaruna: false,
});

export default function karunaSettings(state = initialState, action) {
  switch (action.type) {
    case types.LOGIN_FROM_KARUNA:
      return state.set('loginFromKaruna', action.payload.value);

    default:
      return state;
  }
}
