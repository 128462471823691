import React, { memo, Suspense, useEffect, useState } from 'react';
import { useMountUnmountLogger } from 'src/hooks/useMountUnmountLogger';
import { Authentication } from 'src/utils/authentication/Authentication';
import { useDispatch } from 'react-redux';
import * as appSettingsActions from 'src/redux/appSettings/appSettingsActions';
import LoadingSpinner from './_Layout/LoadingSpinner';
import { DeviceInfoManager } from '@sekoia/shared/utils/DeviceInfoManager';
import { getLocationForDevice } from 'src/requests/location/locationClient';
import LocationBasedDeviceNoAccessForResident from './_Layout/LocationBasedDeviceNoAccessForResident';
import {
  deviceApartmentId,
  deviceChosenResidentIds,
  deviceResidentId,
  setSelectedFiltersToDeviceResident,
} from 'src/redux/device/deviceSettings/deviceSettingsActions';
import UserManagerSettingsLocationBased from 'src/utils/authentication/userManagerSettings/UserManagerSettingsLocationBased';
import { SilentRefresh } from 'src/utils/authentication/SilentRefresh';
import { ApplicationInsightTrackKeys } from 'src/constants/ApplicationInsightTrackKeys';

const AppEmployee = React.lazy(() => import(/* webpackChunkName: "LocationBasedDeviceEntranceApp" */ './AppEmployee'));
const Bootstrap = React.lazy(
  () => import(/* webpackChunkName: "LocationBasedDeviceEntranceBootstrap" */ './Bootstrap'),
);

const LocationBasedDeviceEntrance = () => {
  useMountUnmountLogger('LocationBasedDeviceEntrance');

  localStorage.setItem(ApplicationInsightTrackKeys.ENTRANCE, 'LocationBasedDevice');

  const dispatch = useDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [hasFetchedDeviceResident, setHasFetchedDeviceResident] = useState(false);
  const [noAccessForResident, setNoAccessForResident] = useState(false);

  useEffect(() => {
    const settings = UserManagerSettingsLocationBased();
    Authentication.createInstance(settings);

    initAuth();
  }, []);

  useEffect(() => {
    if (isAuthenticated && !hasFetchedDeviceResident) {
      fetchDeviceResident();
    }
    if (isAuthenticated) {
      dispatch(appSettingsActions.loginFromLocationBasedDevice(true));
    }
    // @TODO Verification of deps needed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const initAuth = async () => {
    const isAuthenticated = await Authentication.Instance.isAuthenticated();
    if (isAuthenticated) {
      setIsAuthenticated(true);
      return;
    }

    if (window.location.hash.indexOf('access_token') !== -1 || window.location.hash.indexOf('code') !== -1) {
      Authentication.Instance.authenticate();
    }
  };

  const fetchDeviceResident = async () => {
    const deviceId = DeviceInfoManager.Instance.getDeviceId();
    if (!deviceId) {
      setHasFetchedDeviceResident(true);
      return;
    }
    getLocationForDevice(deviceId)
      .then((response) => {
        if (response && response.locationId) {
          dispatch(deviceApartmentId(response.locationId));
          if (response.primaryProfileId) {
            dispatch(deviceResidentId(response.primaryProfileId));
            dispatch(setSelectedFiltersToDeviceResident(true));
          }
          if (response.allProfileIds) dispatch(deviceChosenResidentIds(response.allProfileIds));
        }
      })
      .catch((e) => {
        if (e.status === 403) {
          setNoAccessForResident(true);
        }
        if (e.status !== 404) {
          throw e;
        }
      })
      .finally(() => {
        setHasFetchedDeviceResident(true);
      });
  };

  if (!isAuthenticated || !hasFetchedDeviceResident) return <LoadingSpinner />;

  if (noAccessForResident) return <LocationBasedDeviceNoAccessForResident />;

  return (
    <>
      <SilentRefresh />
      <Suspense fallback={<LoadingSpinner />}>
        <Bootstrap isLocationBasedDevice={true}>
          <AppEmployee />
        </Bootstrap>
      </Suspense>
    </>
  );
};

export default memo(LocationBasedDeviceEntrance);
