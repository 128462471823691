export const CACHE_CLEAR = 'CACHE_CLEAR';
export const CACHE_SAVE = 'CACHE_SAVE';
export const DIRECTORY_SEED = 'DIRECTORY_SEED';
export const DIRECTORY_SET_IS_RESIDENT_ACTIVE = 'DIRECTORY_SET_IS_RESIDENT_ACTIVE';
export const ERROR_ADD = 'ERROR_ADD';
export const ERROR_CLEAR = 'ERROR_CLEAR';
export const CONNECTION_CHANGED = 'CONNECTION_CHANGED';
export const SET_TASK_OPTIMISTIC = 'SET_TASK_OPTIMISTIC';
export const CLEAR_TASK_OPTIMISTIC = 'CLEAR_TASK_OPTIMISTIC';
export const SET_SIGNALR_CONNECTION = 'SET_SIGNALR_CONNECTION';

//USER_SETTINGS
export const HIDE_PIN = 'HIDE_PIN';
export const IS_DISABLED_BOTTOM_NAVIGATION = 'IS_DISABLED_BOTTOM_NAVIGATION';
export const LOGIN_FROM_KARUNA = 'LOGIN_FROM_KARUNA';
export const LOGIN_FROM_LOCATION_BASED_DEVICE = 'LOGIN_FROM_LOCATION_BASED_DEVICE';
export const DEVICE_RESIDENT_ID = 'DEVICE_RESIDENT_ID';
export const DEVICE_APARTMENT_ID = 'DEVICE_APARTMENT_ID';
export const DEVICE_CHOSEN_RESIDENT_IDS = 'DEVICE_CHOSEN_RESIDENT_IDS';
export const SET_SELECTED_FILTERS_TO_DEVICE_RESIDENT = 'SET_SELECTED_FILTERS_TO_DEVICE_RESIDENT';

//NEXUS
export const SET_AUTHENTICATION_STATE = 'SET_AUTHENTICATION_STATE';
