import React, { memo, useEffect, useState, Suspense } from 'react';
import { useMountUnmountLogger } from 'src/hooks/useMountUnmountLogger';
import { Authentication } from 'src/utils/authentication/Authentication';
import UserManagerSettingsResident from 'src/utils/authentication/userManagerSettings/UserManagerSettingsResident';
import LoadingSpinner from './_Layout/LoadingSpinner';
import AuthProvider from './AuthProvider';
import { ApplicationInsightTrackKeys } from 'src/constants/ApplicationInsightTrackKeys';

const AppResident = React.lazy(() => import(/* webpackChunkName: "ResidentEntranceAppResident" */ './AppResident'));
const Bootstrap = React.lazy(() => import(/* webpackChunkName: "ResidentEntranceBootstrap" */ './Bootstrap'));

const ResidentEntrance = () => {
  useMountUnmountLogger('ResidentEntrance');

  localStorage.setItem(ApplicationInsightTrackKeys.ENTRANCE, 'Resident');

  useEffect(() => {
    Authentication.createInstance(UserManagerSettingsResident());
    setIsInitialized(true);
  }, []);

  const [isInitialized, setIsInitialized] = useState(false);

  if (!isInitialized) return <LoadingSpinner />;
  return (
    <AuthProvider isResidentEntrance={true}>
      <Suspense fallback={<LoadingSpinner />}>
        <Bootstrap>
          <AppResident />
        </Bootstrap>
      </Suspense>
    </AuthProvider>
  );
};

export default memo(ResidentEntrance);
