import React, { PureComponent } from 'react';

export default class PinDots extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  circleBorder() {
    const { appearance } = this.props;
    return (
      <div
        className={appearance.circleDots}
        style={{
          border: '2px solid ' + this.props.filledColor.toString(),
        }}
      />
    );
  }

  renderDot(filled) {
    return (
      <div style={{ position: 'relative' }}>
        {this.circleBorder()}
        <img
          style={{ position: 'absolute', left: 17, top: 21, display: filled ? 'block' : 'none' }}
          src="images/5Element.png"
        />
      </div>
    );
  }

  render() {
    return (
      <>
        {this.renderDot(this.props.pinEntered.length >= 1)}
        {this.renderDot(this.props.pinEntered.length >= 2)}
        {this.renderDot(this.props.pinEntered.length >= 3)}
        {this.renderDot(this.props.pinEntered.length >= 4)}
      </>
    );
  }
}
