import { useMemo } from 'react';

const nativePaths = ['nativeEntrance', 'locationBasedDeviceEntrance', 'residentDeviceEntrance'];
const residentPath = 'residentDeviceEntrance';

export const useIsNativeFromPath = () => {
  const location = window.location.href;

  return useMemo(() => {
    for (const path of nativePaths) {
      const isNative = location.includes(path);
      if (isNative) {
        return isNative;
      }
    }

    return false;
  }, [location]);
};

export const useIsResidentFromPath = () => {
  const location = window.location.href;

  return useMemo(() => {
    const isResidentDevice = location.includes(residentPath);
    return isResidentDevice;
  }, [location]);
};
