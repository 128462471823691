import { fromJS } from 'immutable';
import * as types from 'src/redux/constants';

const initialState = fromJS({
  HIDE_PIN:
    sessionStorage.getItem('HidePin') === null ? true : sessionStorage.getItem('HidePin') === 'true' ? true : false,
  loginFromKaruna: false,
  isLoggedInFromLocationBasedPinSignin: false,
  isDisabledBottomNavigation: false,
});

export default function appSettings(state = initialState, action) {
  switch (action.type) {
    case types.HIDE_PIN: {
      sessionStorage.setItem('HidePin', action.payload.value);
      return state.set('HIDE_PIN', action.payload.value);
    }

    case types.LOGIN_FROM_LOCATION_BASED_DEVICE:
      return state.set('isLoggedInFromLocationBasedPinSignin', action.payload.value);

    case types.IS_DISABLED_BOTTOM_NAVIGATION:
      return state.set('isDisabledBottomNavigation', action.payload.value);

    default:
      return state;
  }
}
