import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useAtlasClient } from '@sekoia/shared/hooks/common/useAtlasClient';
import CacheKeys from '@sekoia/shared/constants/CacheKeys';
import { EmployeeResponse } from './useFetchEmployees';
import { useQueryWithRetry } from '@sekoia/shared/hooks/helpers/useQueryWithRetry';

export interface ErrorType extends Error {
  status: number;
}

export const useFetchUser = (id?: string, enabled = true) => {
  const { userClient } = useAtlasClient();
  const queryClient = useQueryClient();

  const getUser = useCallback(
    async (id?: string) => {
      if (!id) return null;
      const queryKey = [CacheKeys.EMPLOYEES];
      const employeesCached = queryClient.getQueryData<EmployeeResponse[]>(queryKey);
      if (employeesCached) {
        const employee = employeesCached.find((e) => e.globalId === id);
        if (employee) {
          return {
            firstName: employee.firstname,
            lastName: employee.lastname,
            displayName: employee.displayName,
            legacyId: employee.id,
            globalId: employee.globalId,
            deleted: employee.deleted,
            fullName: `${employee.firstname} ${employee.lastname}`,
          };
        }
      }
      const response = await userClient.getUser(id);
      return response;
    },
    [queryClient, userClient],
  );

  return useQueryWithRetry({
    queryKey: [CacheKeys.USER, id],
    queryFn: () => getUser(id),
    gcTime: 15 * 60 * 1000, // 15 minutes
    enabled: enabled && id ? true : false,
  });
};
