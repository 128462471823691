import { useCallback, useEffect } from 'react';
import { useCustomerInfo } from 'src/hooks/common/useCustomerInfo';
import { Authentication } from 'src/utils/authentication/Authentication';
import { ai } from '@sekoia/shared/utils/telemetryService';

const onlyOnCustomerIds = [
  'C485BB1E-2557-48B1-9ED6-6FC892E86B41', // Dev
  '7B3D3198-5B7F-43FD-BC6F-EE01F340FFC8', // Preprod
  '73110E3D-9E63-483E-887F-3542E82B3E1B', // Storetrae
  '5d32c088b9784b3faf11d8d3021a1203', // RC Care
];

export const logMount = (componentName: string) => {
  const customerId = Authentication.Instance?.customerId;

  if (!customerId || onlyOnCustomerIds.findIndex((id) => id.toLowerCase() === customerId.toLowerCase()) === -1) {
    return;
  }

  ai.trackEvent(`${componentName} mounted`);
};

export const logUnmount = (componentName: string) => {
  const customerId = Authentication.Instance?.customerId;

  if (!customerId || onlyOnCustomerIds.findIndex((id) => id.toLowerCase() === customerId.toLowerCase()) === -1) {
    return;
  }

  ai.trackEvent(`${componentName} unmounted`);
};

export const useMountUnmountLogger = (componentName: string) => {
  useEffect(() => {
    logMount(componentName);

    return () => {
      logUnmount(componentName);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useDoLog = () => {
  const { customerId } = useCustomerInfo();

  const doLog = useCallback((msg: string) => {
    if (!customerId || onlyOnCustomerIds.findIndex((id) => id.toLowerCase() === customerId.toLowerCase()) === -1) {
      return;
    }
    ai.trackEvent(msg);
    // 'customerId' cannot change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return doLog;
};
