import { DeviceInfoManager } from '@sekoia/shared/utils/DeviceInfoManager';
import { ai } from '@sekoia/shared/utils/telemetryService';
import { fetchWithRetry } from 'src/requests/fetchWithRetry';
import loginServiceUrl from 'src/utils/authentication/loginServiceUrl';
import { getSignedMessage } from 'src/utils/nativeCommunication';

type LoginTypeResponse = {
  type: 'RegularLogin' | 'LocationLogin' | 'ResidentLogin';
};

export const getLoginType = async (): Promise<LoginTypeResponse> => {
  const authBaseUrl = loginServiceUrl();
  const deviceId = DeviceInfoManager.Instance.getDeviceId();
  const deviceIdSigned = await getSignedMessage(deviceId);

  const url = `${authBaseUrl}/device/${deviceId}/loginType?deviceIdSigned=${encodeURIComponent(deviceIdSigned)}`;

  const response: Response = await fetchWithRetry(url, {
    headers: {},
  });

  if (response.status !== 200) {
    const error = new Error('Invalid response when trying to determine LoginType');
    ai.trackException(error, 'Invalid response when trying to determine LoginType');
    throw error;
  }

  return await response.json();
};
