const getPublicKeyAction = 'GET_PUBLIC_KEY';
const getSignedMessageAction = 'GET_SIGNED_MESSAGE';
const setCustomerIdAction = 'SET_CUSTOMER_ID';
const nativeTimeout = 2000;

class NativeCommunication {
  constructor(resolve, reject) {
    this.resolve = resolve;
    this.reject = reject;
  }

  onNativeMessage = (event) => {
    if (event.origin !== window.location.origin) {
      return;
    }
    if (event.data && event.data.action) {
      if (event.data.error) {
        this.reject(event.data.error);
        return;
      }

      switch (event.data.action) {
        case getPublicKeyAction:
          this.resolve(event.data.value);
          break;
        case getSignedMessageAction:
          this.resolve(event.data.value);
          break;
        case setCustomerIdAction:
          this.resolve();
          break;
        default:
          break;
      }
    }
  };
}

const initializeNativeCommunication = (resolve, reject) => {
  const comm = new NativeCommunication(resolve, reject);

  window.addEventListener('message', comm.onNativeMessage, false);

  return comm;
};

export const getPublicKey = async () => {
  let comm;
  const publicKey = await new Promise((resolve, reject) => {
    comm = initializeNativeCommunication(resolve, reject);

    window.ReactNativeWebView.postMessage(JSON.stringify({ action: getPublicKeyAction }));

    setTimeout(function () {
      reject(new Error('timeout getting public key from native'));
    }, nativeTimeout);
  }).finally(() => window.removeEventListener('message', comm.onNativeMessage));

  return publicKey;
};

export const getSignedMessage = async (message) => {
  let comm;
  const signature = await new Promise((resolve, reject) => {
    comm = initializeNativeCommunication(resolve, reject);

    window.ReactNativeWebView.postMessage(JSON.stringify({ action: getSignedMessageAction, value: message }));

    setTimeout(function () {
      reject(new Error('timeout getting signature of message from native'));
    }, nativeTimeout);
  }).finally(() => window.removeEventListener('message', comm.onNativeMessage));

  return signature;
};

export const setCustomerId = async (customerId) => {
  let comm;

  await new Promise((resolve, reject) => {
    comm = initializeNativeCommunication(resolve, reject);

    window.ReactNativeWebView.postMessage(JSON.stringify({ action: setCustomerIdAction, value: customerId }));

    setTimeout(function () {
      reject(new Error('timeout getting signature of message from native'));
    }, nativeTimeout);
  }).finally(() => window.removeEventListener('message', comm.onNativeMessage));
};
