import { fromJS } from 'immutable';
import * as types from 'src/redux/constants';

const initialState = fromJS({
  message: null,
  retryHandlers: [],
  forceRetry: false,
});

export default function errorReducer(state = initialState, action) {
  switch (action.type) {
    case types.ERROR_ADD: {
      let { message, onRetry, forceRetry } = action.payload;

      // Only set new message if there is currently no error shown.
      if (!state.get('message', null)) {
        state = state.set('message', message);
      }

      if (onRetry) {
        state = state.updateIn(['retryHandlers'], (handlers) => handlers.push(onRetry));
      }

      if (forceRetry !== undefined) {
        state = state.set('forceRetry', forceRetry);
      }

      return state;
    }
    case types.ERROR_CLEAR: {
      return initialState;
    }
    default:
      return state;
  }
}
