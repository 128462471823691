import React, { memo, Suspense, useEffect, useState } from 'react';
import useQueryParameter from 'src/utils/customHooks/useQueryParameter';
import { Authentication } from 'src/utils/authentication/Authentication';
import { useDispatch } from 'react-redux';
import { loginFromKaruna } from 'src/redux/karuna/karunaSettings/karunaSettingsActions';
import { deviceResidentId } from 'src/redux/device/deviceSettings/deviceSettingsActions';
import { Redirect } from 'react-router-dom';
import LoadingSpinner from 'src/react/_Layout/LoadingSpinner';
import UserManagerSettingsKaruna from 'src/utils/authentication/userManagerSettings/UserManagerSettingsKaruna';
import { ai } from '@sekoia/shared/utils/telemetryService';
import { ApplicationInsightTrackKeys } from 'src/constants/ApplicationInsightTrackKeys';

const AppEmployee = React.lazy(() => import(/* webpackChunkName: "KarunaEntranceApp" */ './AppEmployee'));
const Bootstrap = React.lazy(() => import(/* webpackChunkName: "KarunaEntranceBootstrap" */ './Bootstrap'));

const KarunaEntrance = () => {
  const access_token = useQueryParameter('access_token');
  const residentId = useQueryParameter('residentId');
  const dispatch = useDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  localStorage.setItem(ApplicationInsightTrackKeys.ENTRANCE, 'Karuna');

  useEffect(() => {
    Authentication.createInstance(UserManagerSettingsKaruna());
    if (!access_token) {
      Authentication.Instance.isAuthenticated().then(async (isAuth) => {
        if (!isAuth) {
          ai.trackException(`No isAuth KarunaEntrance, This should not happend!`);
          throw new Error('This should not happend!');
        }
        dispatch(loginFromKaruna(true));
        setIsAuthenticated(true);
      });
    } else {
      Authentication.Instance.karunaSignin(access_token).then(() => {
        dispatch(deviceResidentId(residentId));
        dispatch(loginFromKaruna(true));
        setIsAuthenticated(true);
      });
    }

    document.addEventListener('contextmenu', (event) => event.preventDefault());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isAuthenticated) return <LoadingSpinner />;

  if (access_token) return <Redirect to="/" />;

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Bootstrap loginFromKaruna={true}>
        <AppEmployee isAuthenticated={true} />
      </Bootstrap>
    </Suspense>
  );
};

export default memo(KarunaEntrance);
