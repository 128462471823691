export const messageDialogZIndex = 5100;
export const dialogContentActionButtonZIndex = 5000;
export const pinZIndex = 4000;
export const colleaguePinZIndex = 3000;
export const fullPageLockDivZIndex = 2001;
export const swipeAnimationZIndex = 1100;
export const instructionPlayIconZIndex = 1000;
export const taskDetailFaceFabZIndex = 1000;
export const instructionRightTextZIndex = 300;
export const modalDialogCloseButtonZIndex = 100;
export const suppliersFabContainerZIndex = 50;
export const deviceTaskPopOverZIndex = 5;
export const taskFilterTopZIndex = 3;
