const styles = () => ({
  numpad: {
    width: '100%',
    maxWidth: 380,
  },
  btnNumpad: {
    height: 48,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 24,
  },
  dialogContent: {
    backgroundColor: 'white',
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  dots: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circleDots: {
    padding: 25,
    borderRadius: 30,
    margin: 6,
  },
  root: {
    overflowY: 'unset',
  },
  numpadContainer: { flex: 1, display: 'flex', flexWrap: 'wrap', justifyContent: 'center' },
});

export default styles;
